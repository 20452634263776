import React, { useEffect, useState } from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { useGetAllOrganizationsQuery } from "../../services/organizationApi"
import Title from "../../components/Title"
import { Link, useLocation } from "react-router-dom"
import { Box, Button, CircularProgress, Stack, TableSortLabel, TextField } from "@mui/material"
import OrganizationFormDialog from "./OrganizationFormDialog"
import Organization from "../../types/Organization"
import ColumnSort from "../../types/ColumnSort"
import { visuallyHidden } from '@mui/utils';

type OrganizationGridProps = { organizations?:Organization[], afterSave?:Function, handleSearch?:Function, sort?:ColumnSort, handleSort?:Function}
const OrganizationGrid = (props:OrganizationGridProps) => {
    const { organizations, afterSave, handleSearch, sort, handleSort } = props
    const [searchTerm, setSearchTerm] = useState<string|undefined>(undefined)
    const [openDialog,setOpenDialog] = useState(false)

    const onSearchOrganizations = (evt:any) => {
        setSearchTerm(evt.currentTarget.value.toLowerCase())
        handleSearch && handleSearch(evt.currentTarget.value.toLowerCase())
    }
    
    const onKeyUp = (evt:any) => {
        if (evt.key === 'Enter') {
            handleSearch && handleSearch(searchTerm)
        }
    }

    const openFormDialog = () => {
      setOpenDialog(true)
    }
  
    const closeFormDialog = () => {
      setOpenDialog(false)
    }
    
    const location = useLocation();

    useEffect(() => {
        if (location.state && location.state.searchTerm && location.state.searchTerm.length > 0) {
            setSearchTerm(location.state.searchTerm)
            handleSearch && handleSearch(location.state.searchTerm)
        }
    },[])    

    return (
        <div className="App">
            <Stack direction="row" justifyContent="space-between" spacing={3}>
                <Title>Organizations</Title>
                <Stack direction="row" justifyContent="flex-end">
                    <TextField size="small" style={{ marginRight: 15 }} name="series-search" id="series-search" onChange={onSearchOrganizations} onKeyUpCapture={onKeyUp} placeholder="Search" value={searchTerm}></TextField>
                    <Button color="primary" variant="contained" onClick={openFormDialog}>New Organization</Button>
                </Stack>
            </Stack>

            { <>
                <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell>
                        <TableSortLabel
                            active={ sort?.sortBy === "name"}
                            direction={sort?.sortBy === "name" ? sort?.sortDirection : 'asc'}
                            onClick={() => handleSort && handleSort({
                                sortBy: "name",
                                sortDirection: sort.sortBy === "name" && sort.sortDirection === 'asc' ? 'desc' : 'asc'
                            }) }>
                                Name
                                {sort?.sortBy === "name" ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {sort?.sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>) : null}
                        </TableSortLabel>
                        </TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    { organizations && organizations.length > 0 ? 
                    ([...organizations].map((row) => (
                        <TableRow
                        key={row.id}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                        <TableCell component="th" scope="row">
                            <Link to={`/organizations/${row.id}/`} state={{ searchTerm }}>{row.name}</Link>
                        </TableCell>
                        <TableCell>
                            {row.type}
                        </TableCell>
                        <TableCell>
                            {row.status}
                        </TableCell>
                        <TableCell>
                            <Stack direction="row" justifyContent="flex-end">
                            {/* <Button sx={{m:1}} color="error" variant="contained" data-id={row.id} onClick={() => handleDeleteOrganization(row)}>Delete</Button> */}
                            </Stack>
                        </TableCell>
                        </TableRow>
                    ))) : (
                        <TableRow
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                        <TableCell colSpan={4}>No results.</TableCell>
                        </TableRow>
                    )}
                    </TableBody>
                </Table>
                </TableContainer>
                <OrganizationFormDialog open={openDialog} onClose={closeFormDialog} afterSave={afterSave} fullWidth={true} maxWidth="md" />
                </>
             }                        
        </div>
    )
}

export default OrganizationGrid