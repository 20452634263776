import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface DrawerState { isOpen: boolean }

const initialState = { isOpen: true } satisfies DrawerState as DrawerState

const drawerSlice = createSlice({
    name: 'drawer',
    initialState,
    reducers: {
        setDrawerIsOpen: (state, action: PayloadAction<boolean>) => {
            state.isOpen = action.payload
        }
    },
    selectors: {
        selectDrawerIsOpen: (state) => {
            return state.isOpen
        }
    }
})

export const { setDrawerIsOpen } = drawerSlice.actions
export const { selectDrawerIsOpen } = drawerSlice.selectors
export default drawerSlice