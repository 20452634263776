import { Stack, Typography } from "@mui/material"
import Organization from "../../types/Organization"
import Title from "../Title"
import { defaultDateTimeOptions } from "../../app/constants"

type OrgInfoPanelProps = {organization?:Organization}
const OrganizationInfoPanel = ({organization}:OrgInfoPanelProps) => {

    return (
        <>
            <Title>{organization?.name}</Title>
            <Stack direction="row" spacing={3}>
                { organization?.logoUrl ? (
                    <div style={{backgroundColor:"#000"}}><img src={organization.logoUrl} width={150} /></div>
                ) : (<div></div>)}
                <div>
                    <Typography color="text.secondary" sx={{ flex: 1 }}>
                        <a href={organization?.websiteUrl} target="_blank">{organization?.websiteUrl}</a>
                    </Typography>
                    <Typography variant="body1">Last modified {new Date(organization?.lastModified).toLocaleString('en-US', defaultDateTimeOptions.dateTimeOptions)} by {organization?.modifiedBy}</Typography>                    
                </div>
            </Stack>
        </>
    )
}

export default OrganizationInfoPanel