import { CircularProgress, Grid, Typography } from "@mui/material"
import Facility from "../../types/Facility"
import Title from "../Title"
import { useGetOrganizationByIdQuery } from "../../services/organizationApi"
import User from "../../types/User"
import { ageRangeOptions, contactInfoTypeOptions, defaultDateTimeOptions, demographicInterestOptions, demographicRoleOptions, genderOptions } from "../../app/constants"

type UserDemographicPanelProps = {user?:User}
const UserDemographicPanel = ({user}:UserDemographicPanelProps) => {
// const {data:organization, isLoading:loadingOrg} = useGetOrganizationByIdQuery(facility?.organizationId ?? 0)

    const options = { gender:genderOptions, 
                      ageRange:ageRangeOptions, 
                      preferredContact:contactInfoTypeOptions, 
                      primaryRole:demographicRoleOptions,
                      primaryInterest:demographicInterestOptions }

    const getOption = (optionName:string, optionValue:string) => {
        const optionList = options[optionName];
        const selectedOption = optionList.find(o => o.value === optionValue)

        return selectedOption ? selectedOption.label : optionValue
    }

    const getFacilityInfo = () => {
        const name = user?.userDemographic?.homeFacilityName;
        const city = user?.userDemographic?.homeFacilityCity;
        const state = user?.userDemographic?.homeFacilityState;

        let formattedInfo = 'Not Provided';

        if (name) {
            formattedInfo = name;
        }

        if (city && state) {
            formattedInfo = `${formattedInfo} - ${city}, ${state}`
        }

        return formattedInfo;
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Title>Demographics</Title>
            </Grid>
            <Grid item xs={12} md={3}>
                <Typography variant="body1" color="text.secondary" sx={{borderBottom: 1, borderColor: 'divider', m:1, fontWeight: 'bold'}}>
                    Gender
                </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
                <Typography variant="body1" color="text.secondary" sx={{m:1}}>
                    {user?.userDemographic?.gender ? getOption('gender', user?.userDemographic?.gender) : 'Not Provided'}
                </Typography>                    
            </Grid>
            <Grid item xs={12} md={3}>
                <Typography variant="body1" color="text.secondary" sx={{borderBottom: 1, borderColor: 'divider', m:1, fontWeight: 'bold'}}>
                    Age range
                </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
                <Typography variant="body1" color="text.secondary" sx={{m:1}}>
                    {user?.userDemographic?.ageRange ? getOption('ageRange', user?.userDemographic?.ageRange) : 'Not Provided'}
                </Typography>                    
            </Grid>
            <Grid item xs={12} md={3}>
                <Typography variant="body1" color="text.secondary" sx={{borderBottom: 1, borderColor: 'divider', m:1, fontWeight: 'bold'}}>
                    Role
                </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
                <Typography variant="body1" color="text.secondary" sx={{m:1}}>
                    {user?.userDemographic?.primaryRole ? getOption('primaryRole', user?.userDemographic?.primaryRole) : 'Not Provided'}
                </Typography>                    
            </Grid>
            <Grid item xs={12} md={3}>
                <Typography variant="body1" color="text.secondary" sx={{borderBottom: 1, borderColor: 'divider', m:1, fontWeight: 'bold'}}>
                    Interest
                </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
                <Typography variant="body1" color="text.secondary" sx={{m:1}}>
                    {user?.userDemographic?.primaryInterest ? getOption('primaryInterest', user?.userDemographic?.primaryInterest) : 'Not Provided'}
                </Typography>                    
            </Grid>
            <Grid item xs={12} md={3}>
                <Typography variant="body1" color="text.secondary" sx={{borderBottom: 1, borderColor: 'divider', m:1, fontWeight: 'bold'}}>
                    Organizations/Associations
                </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
                <Typography variant="body1" color="text.secondary" sx={{m:1}}>
                    {user?.userDemographic?.organizations ? user?.userDemographic?.organizations : 'Not Provided'}
                </Typography>                    
            </Grid>
            <Grid item xs={12} md={3}>
                <Typography variant="body1" color="text.secondary" sx={{borderBottom: 1, borderColor: 'divider', m:1, fontWeight: 'bold'}}>
                    Home Facility
                </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
                <Typography variant="body1" color="text.secondary" sx={{m:1}}>
                    {getFacilityInfo()}
                </Typography>                    
            </Grid>                                                                                       
        </Grid>
    )
}

export default UserDemographicPanel