import { APIProvider, Map, MapCameraChangedEvent, MapCameraProps, Marker } from "@vis.gl/react-google-maps";
import { Grid, Paper } from '@mui/material'
import Facility from "../../types/Facility"
import { useCallback, useState } from "react";

type FacilityMapProps = { facility:Facility }
const FacilityMap = ({facility}:FacilityMapProps) => {

    const mapCenter = { lat: facility.address.latitude, lng: facility.address.longitude }
    const mapZoom = 10

    const [cameraProps, setCameraProps] = useState<MapCameraProps>({center: mapCenter, zoom: mapZoom});
    const handleCameraChange = (ev: MapCameraChangedEvent) => setCameraProps(ev.detail);    

    return (
        <APIProvider apiKey="AIzaSyAVRJYOW8mg7wJEIEAJaGJwZlH0KXt1UR4">
            <Map {...cameraProps} onCameraChanged={handleCameraChange} style={{height:460}}>
                <Marker
                    position={{lat: facility.address.latitude, lng: facility.address.longitude}}
                    title={facility.name}></Marker>
            </Map>
        </APIProvider>
    )
}

export default FacilityMap

