import Dashboard from "./Dashboard";
import { CircularProgress, Grid, Paper, TablePagination } from '@mui/material'
import { useEffect, useState } from "react";
import Paged from "../types/Paged";
import { useDebounce } from "../app/hooks";
import { GetPagedArgs } from "../app/queries";
import { pagination } from "../app/constants";
import UserFeedback from "../types/UserFeedback";
import { useLazyFindFeedbackBySearchTermPageQuery, useLazyGetFeedbackPageQuery } from "../services/feedbackApi";
import FeedbackGrid from "../components/feedback/FeedbackGrid";
import ColumnSort from "../types/ColumnSort";

const FeedbackPage = (props:any) => {
    //const {data:facilities, error, isLoading} = useGetAllFacilitiesQuery()

    const [pageNumber, setPageNumber] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(pagination.defaultItemsPerPage)
    const [sort, setSort] = useState({ sortBy: "lastModified", sortDirection: "desc"} as ColumnSort)
    const [isLoading, setIsLoading] = useState(false)
    const [feedbackPage, setFeedbackPage] = useState<Paged<UserFeedback>>(undefined)
    const [searchTerm, setSearchTerm]= useState<string|undefined>(undefined)
    const [getFeedbackPage] = useLazyGetFeedbackPageQuery()
    const [findFeedbackBySearchTermPage] = useLazyFindFeedbackBySearchTermPageQuery()
    const debouncedSearchTerm = useDebounce(searchTerm, 500)

    useEffect(() => {
      setIsLoading(true)
      getFeedback()
      setIsLoading(false)
    },[pageNumber,itemsPerPage,sort,debouncedSearchTerm])    
    
    const fetchFeedbackPage = () => {
      const pageArgs : GetPagedArgs = {
        pageNumber,
        pageSize: itemsPerPage,
        sortBy: sort.sortBy,
        sortDirection: sort.sortDirection
      }

      getFeedbackPage(pageArgs).then(sp => setFeedbackPage(sp.data))
    }

    const searchFeedbackPage = () => {
      const pageArgs : GetPagedArgs = {
        searchTerm: debouncedSearchTerm,
        pageNumber,
        pageSize: itemsPerPage,
        sortBy: sort.sortBy,
        sortDirection: sort.sortDirection
      }

      findFeedbackBySearchTermPage(pageArgs).then(sp => setFeedbackPage(sp.data))
    }

    const getFeedback = () => {
      if (!debouncedSearchTerm || debouncedSearchTerm.length == 0) {
        fetchFeedbackPage()
      } else {
        searchFeedbackPage()
      }
    }
    
    const handleSave = () => {
      getFeedback()
      setPageNumber(1)
    }

    const handleChangePage = (event: unknown, newPage: number) => {
      setPageNumber(newPage+1);
    };
  
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setItemsPerPage(+event.target.value);
      setPageNumber(1);
    };
    
    const handleSearch = (searchTerm:string) => {
      setSearchTerm(searchTerm)
    }
    
    const handleSort = (updatedSort:ColumnSort) => {
      setSort(updatedSort)
      setPageNumber(1)
    }    

    return (
        <Dashboard>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                    { isLoading ? <CircularProgress/> : 
                    <>
                    <FeedbackGrid messages={feedbackPage ? feedbackPage.items : []} showSearchBox={true} afterSave={handleSave} handleSearch={handleSearch} sort={sort} handleSort={handleSort} />
                    <TablePagination
                          rowsPerPageOptions={pagination.itemsPerPageOptions}
                          component="div"
                          count={feedbackPage?.totalItems ?? 0}
                          rowsPerPage={itemsPerPage}
                          page={pageNumber-1}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage} />                    
                    </>  }
                </Paper>
              </Grid>
            </Grid>
        </Dashboard>        
    )
}

export default FeedbackPage