import { CircularProgress, Grid, Typography } from "@mui/material"
import Facility from "../../types/Facility"
import Title from "../Title"
import { useGetOrganizationByIdQuery } from "../../services/organizationApi"
import Schedule from "../../types/Schedule"
import { defaultDateTimeOptions } from "../../app/constants"

type SchedInfoPanelProps = {schedule?:Schedule}
const ScheduleInfoPanel = ({schedule}:SchedInfoPanelProps) => {

    const startTime = new Date(new Date().toDateString() + ' ' + schedule?.startTime).toLocaleTimeString('en-US',defaultDateTimeOptions.timeOptions)
    const endTime = new Date(new Date().toDateString() + ' ' + schedule?.endTime).toLocaleTimeString('en-US',defaultDateTimeOptions.timeOptions)

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Title>{schedule?.label}</Title>
            </Grid>
            <Grid item xs={12} md={3}>
                <Typography variant="h6" color="text.secondary" sx={{borderBottom: 1, borderColor: 'divider', m:1}}>
                    Times
                </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
                <Typography variant="body1">{startTime} - {endTime}</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
                <Typography variant="h6" color="text.secondary" sx={{borderBottom: 1, borderColor: 'divider', m:1}}>
                    Days
                </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
                <Typography variant="body1">{schedule?.daysOfWeek.join(', ')}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="text.secondary" sx={{borderBottom: 1, borderColor: 'divider', m:1}}>
                    Last Modified
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1">{new Date(schedule?.lastModified).toLocaleString('en-US', defaultDateTimeOptions.dateTimeOptions)} by {schedule?.modifiedBy}</Typography>
            </Grid>            
        </Grid>
    )
}

export default ScheduleInfoPanel