import { useState } from "react"
import Schedule from "../../types/Schedule"
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Typography, Alert, Button, Grid, Stack, TextField, Select, MenuItem, FormControl, FormHelperText, InputLabel, CircularProgress, FormLabel, FormGroup, FormControlLabel, Checkbox } from "@mui/material"
import { useCreateScheduleMutation, useUpdateScheduleMutation } from "../../services/scheduleApi"
import { dayOfWeekOptions } from "../../app/constants"
import { useAuthProvider } from "../../app/context"

type ScheduleFormProps = { schedule:Schedule }
type ScheduleFormValues = {
    id: number,
    label: string,
    startTime: string,
    endTime: string,
    daysOfWeek: string[]
}

const ScheduleForm = ({schedule}:ScheduleFormProps) => {
    const [showAlert, setShowAlert] = useState(false)
    const [alertSeverity, setAlertSeverity] = useState<any>('success')
    const [alertContent, setAlertContent] = useState('')
    const [createSchedule] = useCreateScheduleMutation()
    const [updateSchedule] = useUpdateScheduleMutation()
    const authProvider = useAuthProvider()

    const validationSchema = Yup.object().shape({
        label: Yup.string().required('Name is required'),
        startTime: Yup.string().required('Start time is required'),
        endTime: Yup.string().required('End time is required'),
        daysOfWeek: Yup.array().default([]).min(1, 'At least one must be selected').required()
    })

    const mapValuesToSchedule = async (values:any) : Promise<Schedule> => {
        const updateUser = await authProvider.getUser()
        const updateUsername = updateUser.email ?? updateUser.preferred_username

        let sched = {
            id: values.id,
            label: values.label,
            startTime: values.startTime,
            endTime: values.endTime,
            daysOfWeek: values.daysOfWeek,
            lastModified: new Date(),
            modifiedBy: updateUsername,
            status: 'ACTIVE'
        } as Schedule

        return sched
    }

    const mapScheduleToValues = (schedule:Schedule) : ScheduleFormValues => {
        return {
            id: schedule.id,
            label: schedule.label,
            startTime: schedule.startTime,
            endTime: schedule.endTime,
            daysOfWeek: schedule.daysOfWeek || []
        } as ScheduleFormValues
    }

    const formik = useFormik({
        initialValues: mapScheduleToValues(schedule),
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => handleSubmit(values)
    })

    const handleSubmit = async (values:ScheduleFormValues) => {
        setShowAlert(false)
        setAlertContent('Saving...')
        setAlertSeverity('info')
        setShowAlert(true)
        
        const updateFn = (values.id > 0 ? updateSchedule : createSchedule)

        try {
            let updatedSched = await updateFn(await mapValuesToSchedule(values)).unwrap()
            formik.setValues(updatedSched)
            setAlertContent(`Schedule ${updatedSched.label} saved.`)
            setAlertSeverity('success')
            setShowAlert(true)            
        } catch (error:any) {
            formik.setErrors(error)
            setAlertContent('Error saving schedule.')
            setAlertSeverity('error')
            setShowAlert(true)             
        }
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={1}>
            {showAlert ? (
                <Grid item xs={12}>
                    <Alert severity={alertSeverity}>{alertContent}</Alert>    
                </Grid>
            ) : (<></>)}
                <Grid item xs={12}>
                <TextField
                        sx={{m: 1}}
                        id="label"
                        name="label"
                        label="Schedule Name"
                        value={formik.values.label}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.label && Boolean(formik.errors.label)}
                        helperText={formik.touched.label && formik.errors.label}/>                    
                </Grid>
                <Grid item xs={12} md={3}>
                <TextField
                        sx={{m: 1}}
                        type="time"
                        id="startTime"
                        name="startTime"
                        label="Start Time"
                        value={formik.values.startTime}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.startTime && Boolean(formik.errors.startTime)}
                        helperText={formik.touched.startTime && formik.errors.startTime}/>                    
                </Grid>
                <Grid item xs={12} md={3}>
                <TextField
                        sx={{m: 1}}
                        type="time"
                        id="endTime"
                        name="endTime"
                        label="End Time"
                        value={formik.values.endTime}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.endTime && Boolean(formik.errors.endTime)}
                        helperText={formik.touched.endTime && formik.errors.endTime}/>                    
                </Grid>
                <Grid item xs={12}>
                <FormControl component="fieldset" variant="standard" error={formik.touched.daysOfWeek && Boolean(formik.errors.daysOfWeek)}>
                    <FormLabel component="legend">Days of Week</FormLabel>
                    <FormGroup row>
                        {dayOfWeekOptions.map((opt, index) => (
                            <FormControlLabel
                                key={opt.value}
                                label={opt.label}
                                control={
                                    <Checkbox
                                        name="daysOfWeek" 
                                        value={opt.value}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        checked={formik.values.daysOfWeek && formik.values.daysOfWeek.some((v) => v===opt.value)}/>
                                }/>
                        ))}
                    </FormGroup>
                    <FormHelperText>{formik.touched.daysOfWeek && formik.errors.daysOfWeek}</FormHelperText>
                </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="end">
                        <Button color="primary" variant="contained" type="submit" sx={{m: 1, alignSelf: 'right'}}>
                        {formik.values.id ? ('Update') : ('Create')}
                        </Button>
                    </Stack>
                </Grid>                                                                                                    
            </Grid>            
        </form>
    )
}

export default ScheduleForm