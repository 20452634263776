import React, { useEffect, useState } from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import Title from "../../components/Title"
import { Link, useLocation } from "react-router-dom"
import { Box, Button, Stack, TableSortLabel, TextField } from "@mui/material"
import Facility from "../../types/Facility"
import FacilityFormDialog from "./FacilityFormDialog"
import ColumnSort from "../../types/ColumnSort"
import { visuallyHidden } from '@mui/utils'


type FacilityGridProps = { facilities?:Facility[], allowNewFacility?:Boolean, showSearchBox?:Boolean, organizationId?:number, afterSave?:Function, handleSearch?:Function, sort?:ColumnSort, handleSort?:Function }
const FacilityGrid = (props:FacilityGridProps) => {
    
    const { facilities, allowNewFacility, organizationId, afterSave, showSearchBox, handleSearch, sort, handleSort } = props

    const [openDialog,setOpenDialog] = useState(false)
    const [searchTerm, setSearchTerm] = useState<string|undefined>(undefined)

    const onSearchFacilities = (evt:any) => {
        setSearchTerm(evt.currentTarget.value.toLowerCase())
        handleSearch && handleSearch(evt.currentTarget.value.toLowerCase())
    }

    const openFormDialog = () => {
      setOpenDialog(true)
    }
  
    const closeFormDialog = () => {
      setOpenDialog(false)
    }

    const onKeyUp = (evt:any) => {
        if (evt.key === 'Enter') {
            handleSearch && handleSearch(searchTerm)
        }
    }

    const location = useLocation();

    useEffect(() => {
        console.log(globalThis.location)
        console.log(globalThis.history)
        if (location.state && location.state.searchTerm && location.state.searchTerm.length > 0) {
            setSearchTerm(location.state.searchTerm)
            handleSearch && handleSearch(location.state.searchTerm)
        }
    },[])

    return (
        <div className="App">
            <Stack direction="row" justifyContent="space-between" spacing={3}>
                <Title>Facilities</Title>
                <Stack direction="row" justifyContent="flex-end">
                { showSearchBox && <TextField size="small" style={{ marginRight: 15 }} name="series-search" id="series-search" onChange={onSearchFacilities} onKeyUpCapture={onKeyUp} placeholder="Search" value={searchTerm}></TextField>}    
                { allowNewFacility && <Button color="primary" variant="contained" onClick={openFormDialog}>New Facility</Button> }
                </Stack>
            </Stack>

            { <>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                    <TableCell>
                        <TableSortLabel
                            active={ sort?.sortBy === "name"}
                            direction={sort?.sortBy === "name" ? sort?.sortDirection : 'asc'}
                            onClick={() => handleSort && handleSort({
                                sortBy: "name",
                                sortDirection: sort.sortBy === "name" && sort.sortDirection === 'asc' ? 'desc' : 'asc'
                            }) }>
                                Name
                                {sort?.sortBy === "name" ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {sort?.sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>) : null}
                        </TableSortLabel>
                        </TableCell>
                        <TableCell>
                        <TableSortLabel
                            active={ sort?.sortBy === "city"}
                            direction={sort?.sortBy === "city" ? sort?.sortDirection : 'asc'}
                            onClick={() => handleSort && handleSort({
                                sortBy: "city",
                                sortDirection: sort.sortBy === "city" && sort.sortDirection === 'asc' ? 'desc' : 'asc'
                            }) }>
                                City
                                {sort?.sortBy === "city" ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {sort?.sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>) : null}
                        </TableSortLabel>
                        </TableCell>
                        <TableCell>
                        <TableSortLabel
                            active={ sort?.sortBy === "stateprovince"}
                            direction={sort?.sortBy === "stateprovince" ? sort?.sortDirection : 'asc'}
                            onClick={() => handleSort && handleSort({
                                sortBy: "stateprovince",
                                sortDirection: sort.sortBy === "stateprovince" && sort.sortDirection === 'asc' ? 'desc' : 'asc'
                            }) }>
                                State/Province
                                {sort?.sortBy === "stateprovince" ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {sort?.sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>) : null}
                        </TableSortLabel>
                        </TableCell>
                        <TableCell>Country</TableCell>
                        <TableCell>
                        <TableSortLabel
                            active={ sort?.sortBy === "usersavecount"}
                            direction={sort?.sortBy === "usersavecount" ? sort?.sortDirection : 'asc'}
                            onClick={() => handleSort && handleSort({
                                sortBy: "usersavecount",
                                sortDirection: sort.sortBy === "usersavecount" && sort.sortDirection === 'asc' ? 'desc' : 'asc'
                            }) }>
                                Number of Saves
                                {sort?.sortBy === "usersavecount" ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {sort?.sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>) : null}
                        </TableSortLabel>
                        </TableCell>                        
                    </TableRow>
                    </TableHead>                        
                        <TableBody>
                    {facilities && facilities.length > 0 ? ([...facilities].map((row) => (
                        <TableRow
                        key={row.id}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                        <TableCell component="th" scope="row">
                            <Link to={`/facilities/${row.id}`} state={{searchTerm, prevPage: location.pathname}}>{row.name}</Link>
                        </TableCell>
                        <TableCell>{row.address.city}</TableCell>
                        <TableCell>{row.address.stateProvince}</TableCell>
                        <TableCell>{row.address.country}</TableCell>
                        <TableCell>{row.userSaveCount || 0}</TableCell>
                        </TableRow>
                    ))) : (
                        <TableRow
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                        <TableCell colSpan={4}>No results.</TableCell>
                        </TableRow>
                    )}
                    </TableBody>                        
                    </Table>
                </TableContainer>
                    <FacilityFormDialog organizationId={organizationId} open={openDialog} onClose={closeFormDialog} afterSave={afterSave} fullWidth={true} maxWidth="md" />
                </>}                        
        </div>
    )
}

export default FacilityGrid