import { CircularProgress, Grid, Typography } from "@mui/material"
import Facility from "../../types/Facility"
import Title from "../Title"
import { useGetOrganizationByIdQuery } from "../../services/organizationApi"
import User from "../../types/User"
import { ageRangeOptions, contactInfoTypeOptions, defaultDateTimeOptions, demographicInterestOptions, demographicRoleOptions, feedbackStatusOptions, feedbackSubjectOptions, genderOptions } from "../../app/constants"
import UserFeedback from "../../types/UserFeedback"
import { useGetUserByIdQuery, useLazyGetUserByIdQuery } from "../../services/userApi"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"

type FeedbackDetailPanelProps = {message?:UserFeedback}
const FeedbackDetailPanel = ({message}:FeedbackDetailPanelProps) => {
    const [getUserById] = useLazyGetUserByIdQuery()
    const [user, setUser] = useState(undefined as User)

    const options = { feedbackSubject:feedbackSubjectOptions, feedbackStatus:feedbackStatusOptions }

    const getOption = (optionName:string, optionValue:string) => {
        const optionList = options[optionName];
        const selectedOption = optionList.find(o => o.value === optionValue)

        return selectedOption ? selectedOption.label : optionValue
    }

    useEffect(() => {
        if (message.userId) {
            getUserById(message.userId).then((result) => setUser(result.data))
        }
    }, [])

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Title>Feedback Message</Title>
            </Grid>
            <Grid item xs={12} md={3}>
                <Typography variant="body1" color="text.secondary" sx={{borderBottom: 1, borderColor: 'divider', m:1, fontWeight: 'bold'}}>
                    From
                </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
                <Typography variant="body1" color="text.secondary" sx={{m:1}}>
                    { user
                    ? ( 
                        <Link to={`/users/${message.userId}`}>
                            {user.relatedPerson?.givenName ? user.relatedPerson?.givenName : ""} {user.relatedPerson?.surname ? user.relatedPerson?.surname : ""} {user.email ? `(${user.email})` : ""}
                        </Link>)
                    : (
                        <>{message.name ? message.name : ""} {message.email ? `(${message.email})` : ""}</>
                    ) }
                </Typography>                    
            </Grid>
            <Grid item xs={12} md={3}>
                <Typography variant="body1" color="text.secondary" sx={{borderBottom: 1, borderColor: 'divider', m:1, fontWeight: 'bold'}}>
                    Subject
                </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
                <Typography variant="body1" color="text.secondary" sx={{m:1}}>
                {getOption('feedbackSubject', message.subject)}
                </Typography>                    
            </Grid>
            <Grid item xs={12} md={3}>
                <Typography variant="body1" color="text.secondary" sx={{borderBottom: 1, borderColor: 'divider', m:1, fontWeight: 'bold'}}>
                    Status
                </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
                <Typography variant="body1" color="text.secondary" sx={{m:1}}>
                    {message.feedbackStatus ? getOption('feedbackStatus', message.feedbackStatus) : ""}
                </Typography>                    
            </Grid>
            <Grid item xs={12} md={3}>
                <Typography variant="body1" color="text.secondary" sx={{borderBottom: 1, borderColor: 'divider', m:1, fontWeight: 'bold'}}>
                    Message
                </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
                <Typography variant="body1" color="text.secondary" sx={{m:1}}>
                    {message.message}
                </Typography>                    
            </Grid>                                                                                      
        </Grid>
    )
}

export default FeedbackDetailPanel