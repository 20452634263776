import {Dialog, DialogTitle, Grid} from '@mui/material'
import OrganizationForm from './OrganizationForm'
import Organization from '../../types/Organization'

const OrganizationFormDialog = (props:any) => {

    const {open, onClose, afterSave, ...otherProps } = props

    return (
        <Dialog open={open} onClose={onClose} {...otherProps}>
            <DialogTitle>New Organization</DialogTitle>
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{m:2}}>
                    <OrganizationForm organization={ {} as Organization } afterSave={afterSave}></OrganizationForm>
                </Grid>
            </Grid>
        </Dialog>
    )
}

export default OrganizationFormDialog