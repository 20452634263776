import {Dialog, DialogTitle, Grid} from '@mui/material'
import FeedbackDetailPanel from './FeedbackDetailPanel'

const FeedbackDetailDialog = (props:any) => {

    const {open, onClose, feedback, ...otherProps} = props

    return (
        <Dialog open={open} onClose={onClose} {...otherProps}>
            <DialogTitle>{otherProps.dialogTitle}</DialogTitle>
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{m:2}}>
                    <FeedbackDetailPanel message={feedback}></FeedbackDetailPanel>
                </Grid>
            </Grid>
        </Dialog>
    )
}

export default FeedbackDetailDialog