import { CircularProgress, Grid, Typography } from "@mui/material"
import Facility from "../../types/Facility"
import Title from "../Title"
import { useGetOrganizationByIdQuery } from "../../services/organizationApi"
import User from "../../types/User"
import { defaultDateTimeOptions } from "../../app/constants"

type UserInfoPanelProps = {user?:User}
const UserInfoPanel = ({user}:UserInfoPanelProps) => {
// const {data:organization, isLoading:loadingOrg} = useGetOrganizationByIdQuery(facility?.organizationId ?? 0)

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Title>{user?.username}</Title>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="text.secondary" sx={{borderBottom: 1, borderColor: 'divider', m:1}}>
                    Name
                </Typography>
            </Grid>
            <Grid item xs={12}>
                { user?.relatedPerson ? `${user?.relatedPerson.givenName || ''} ${user?.relatedPerson.surname || ''}` : ''}
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="text.secondary" sx={{borderBottom: 1, borderColor: 'divider', m:1}}>
                    Contact
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1">{user?.email}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1">{user?.mobile}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1">Contact preference: {user?.preferredContactType ? user?.preferredContactType : 'Not Provided'}</Typography>    
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography variant="body1">OK to contact for product updates? {user?.optInUpdateMessages ? 'Yes' : 'No'}</Typography>    
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography variant="body1">OK to contact for third parties? {user?.optInPartnerMessages ? 'Yes' : 'No'}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="text.secondary" sx={{borderBottom: 1, borderColor: 'divider', m:1}}>
                    Last Modified
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1">{new Date(user?.lastModified).toLocaleString('en-US', defaultDateTimeOptions.dateTimeOptions)} by {user?.modifiedBy}</Typography>
            </Grid>                        
        </Grid>
    )
}

export default UserInfoPanel