import Dashboard from "./Dashboard"
import OrganizationGrid from "../components/organization/OrganizationGrid"
import { CircularProgress, Grid, Paper, TablePagination } from "@mui/material"
import { useEffect, useState } from "react"
import Paged from "../types/Paged"
import Organization from "../types/Organization"
import { useLazyGetOrganizationPageQuery, useLazyFindOrganizationsBySearchTermPageQuery } from "../services/organizationApi"
import { useDebounce } from "../app/hooks"
import { GetPagedArgs } from "../app/queries"
import { pagination } from "../app/constants"
import ColumnSort from "../types/ColumnSort"

const OrganizationPage = (props:any) => {
    
    const [pageNumber, setPageNumber] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(pagination.defaultItemsPerPage)
    const [sort, setSort] = useState({ sortBy: "name", sortDirection: "asc"} as ColumnSort)
    const [isLoading, setIsLoading] = useState(false)
    const [organizationPage, setOrganizationPage] = useState<Paged<Organization>>(undefined)
    const [searchTerm, setSearchTerm]= useState<string|undefined>(undefined)
    const [getOrganizationPage] = useLazyGetOrganizationPageQuery()
    const [findOrganizationsBySearchTermPage] = useLazyFindOrganizationsBySearchTermPageQuery()
    const debouncedSearchTerm = useDebounce(searchTerm, 500)        

    const fetchOrganizationPage = () => {
        const pageArgs : GetPagedArgs = {
          pageNumber,
          pageSize: itemsPerPage,
          sortBy: sort.sortBy,
          sortDirection: sort.sortDirection
        }
  
        getOrganizationPage(pageArgs).then(sp => setOrganizationPage(sp.data))
      }
  
      const searchOrganizationPage = () => {
        const pageArgs : GetPagedArgs = {
          searchTerm: debouncedSearchTerm,
          pageNumber,
          pageSize: itemsPerPage,
          sortBy: sort.sortBy,
          sortDirection: sort.sortDirection
        }
  
        findOrganizationsBySearchTermPage(pageArgs).then(sp => setOrganizationPage(sp.data))
      }
      
      const handleSort = (sortUpdate:ColumnSort) => {
        setSort(sortUpdate)
        setPageNumber(1)
      }

      const handleSave = () => {
        if (debouncedSearchTerm && debouncedSearchTerm.length > 0) {
          searchOrganizationPage()
        }
        setPageNumber(1)
        //setSort({ sortBy: "name", sortDirection: "asc" })
      }
  
      const handleChangePage = (event: unknown, newPage: number) => {
        setPageNumber(newPage+1);
      };
    
      const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setItemsPerPage(+event.target.value);
        setPageNumber(1);
      };
      
      const handleSearch = (searchTerm:string) => {
        setSearchTerm(searchTerm)
      }
      
      useEffect(() => {
        setIsLoading(true)
        if (!debouncedSearchTerm || debouncedSearchTerm.length == 0) {
          fetchOrganizationPage()
        } else {
          searchOrganizationPage()
        }
        setIsLoading(false)
      },[pageNumber,itemsPerPage,sort,debouncedSearchTerm])      
    
    return (
        <Dashboard>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                    { isLoading ? <CircularProgress/> :
                        <>
                        <OrganizationGrid organizations={organizationPage ? organizationPage.items : []} afterSave={handleSave} handleSearch={handleSearch} sort={sort} handleSort={handleSort} />
                        <TablePagination
                          rowsPerPageOptions={pagination.itemsPerPageOptions}
                          component="div"
                          count={organizationPage?.totalItems ?? 0}
                          rowsPerPage={itemsPerPage}
                          page={pageNumber-1}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage} />                         
                        </>
                    }
                </Paper>
              </Grid>
            </Grid>
        </Dashboard>
    )
}

export default OrganizationPage