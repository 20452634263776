import Dashboard from "./Dashboard";
import SeriesGrid from "../components/series/SeriesGrid";
import { CircularProgress, Grid, Paper, TablePagination } from '@mui/material'
import { useLazyFindSeriesBySearchTermPageQuery, useLazyGetSeriesPageQuery } from "../services/seriesApi";
import { useEffect, useState } from "react";
import Paged from "../types/Paged";
import Series from "../types/Series";
import { GetPagedArgs } from "../app/queries";
import { useDebounce } from "../app/hooks";
import { pagination } from "../app/constants";
import ColumnSort from "../types/ColumnSort";

const SeriesPage = (props:any) => {
    // const {data:series, error, isLoading, refetch:refetchSeries} = useGetAllSeriesQuery()

    const [pageNumber, setPageNumber] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(pagination.defaultItemsPerPage)
    const [sort, setSort] = useState({ sortBy: "seriesname", sortDirection: "asc"} as ColumnSort)
    const [isLoading, setIsLoading] = useState(false)
    const [seriesPage, setSeriesPage] = useState<Paged<Series>>(undefined)
    const [searchTerm, setSearchTerm]= useState<string|undefined>(undefined)
    const [getSeriesPage] = useLazyGetSeriesPageQuery()
    const [findSeriesBySearchTermPage] = useLazyFindSeriesBySearchTermPageQuery()
    const debouncedSearchTerm = useDebounce(searchTerm, 500)

    const fetchSeriesPage = () => {
      const pageArgs : GetPagedArgs = {
        pageNumber,
        pageSize: itemsPerPage,
        sortBy: sort.sortBy,
        sortDirection: sort.sortDirection
      }

      getSeriesPage(pageArgs).then(sp => setSeriesPage(sp.data))
    }

    const searchSeriesPage = () => {
      const pageArgs : GetPagedArgs = {
        searchTerm: debouncedSearchTerm,
        pageNumber,
        pageSize: itemsPerPage,
        sortBy: sort.sortBy,
        sortDirection: sort.sortDirection
      }

      findSeriesBySearchTermPage(pageArgs).then(sp => setSeriesPage(sp.data))
    }
    
    const handleSort = (updatedSort:ColumnSort) => {
      setSort(updatedSort)
      setPageNumber(1)
    }

    const handleSave = () => {
      console.log('debouncedSearchTerm: ', debouncedSearchTerm)
      if (debouncedSearchTerm && debouncedSearchTerm.length > 0) {
        console.log("search after save")
        searchSeriesPage()
      }
      setPageNumber(1)
      //setSort({ sortBy: "seriesName", sortDirection: "asc" })
    }

    const handleChangePage = (event: unknown, newPage: number) => {
      setPageNumber(newPage+1);
    };
  
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setItemsPerPage(+event.target.value);
      setPageNumber(1);
    };
    
    const handleSearch = (searchTerm:string) => {
      setSearchTerm(searchTerm)
    }

    useEffect(() => {
      setIsLoading(true)
      if (!debouncedSearchTerm || debouncedSearchTerm.length == 0) {
        fetchSeriesPage()
      } else {
        searchSeriesPage()
      }
      setIsLoading(false)
    },[pageNumber,itemsPerPage,sort,debouncedSearchTerm])    

    return (
        <Dashboard>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                    { isLoading ? <CircularProgress/> : 
                      <>
                      <SeriesGrid series={seriesPage ? seriesPage.items : []} showFacilityInfo showSearchBox allowNewSeries afterSave={handleSave} handleSearch={handleSearch} sort={sort} handleSort={handleSort} />
                      <TablePagination
                          rowsPerPageOptions={pagination.itemsPerPageOptions}
                          component="div"
                          count={seriesPage?.totalItems ?? 0}
                          rowsPerPage={itemsPerPage}
                          page={pageNumber-1}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage} />
                      </> }
                </Paper>
              </Grid>
            </Grid>
        </Dashboard>        
    )
}

export default SeriesPage