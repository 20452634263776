import { api } from '../app/api'
import { GetPagedArgs } from '../app/queries'
import UserFeedback from '../types/UserFeedback'
import Paged from '../types/Paged'

const feedbackApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getFeedbackPage: builder.query<Paged<UserFeedback>,GetPagedArgs>({
            query: ({pageNumber, pageSize, sortBy, sortDirection}) => {
                let searchParams : URLSearchParams = new URLSearchParams();
                searchParams.set('pageNumber',pageNumber.toString());
                searchParams.set('pageSize',pageSize.toString());

                if (sortBy && sortBy !== "") {
                    searchParams.set('sortBy', sortBy);
                }

                if (sortDirection && sortDirection !== "") {
                    searchParams.set('sortDirection', sortDirection);
                }

                return `userfeedback/paged?${searchParams.toString()}`;
            },
            transformResponse: ({_embedded, page}) => {
                const { number, size, totalElements, totalPages } = page
                let pagedResponse : Paged<UserFeedback> = {
                    items: _embedded && _embedded.userfeedback ? _embedded.userfeedback : [],
                    page: number+1,
                    size: size,
                    totalItems: totalElements,
                    totalPages: totalPages
                }

                return pagedResponse
            }            
        }),
        findFeedbackBySearchTermPage: builder.query<Paged<UserFeedback>, GetPagedArgs>({
            query: ({searchTerm, pageNumber, pageSize, sortBy, sortDirection}) => {
                let searchParams : URLSearchParams = new URLSearchParams();
                searchParams.set('searchTerm',searchTerm);
                searchParams.set('pageNumber',pageNumber.toString());
                searchParams.set('pageSize',pageSize.toString());

                if (sortBy && sortBy !== "") {
                    searchParams.set('sortBy', sortBy);
                }

                if (sortDirection && sortDirection !== "") {
                    searchParams.set('sortDirection', sortDirection);
                }

                return `userfeedback/search/findByFuzzyCriteria/paged?${searchParams.toString()}`;
            },
            transformResponse: ({_embedded, page}) => {
                const { number, size, totalElements, totalPages } = page
                let pagedResponse : Paged<UserFeedback> = {
                    items: _embedded && _embedded.userfeedback ? _embedded.userfeedback : [],
                    page: number+1,
                    size: size,
                    totalItems: totalElements,
                    totalPages: totalPages
                }

                return pagedResponse
            }              
        }),
        getAllFeedback: builder.query<UserFeedback[],void>({
            query: () => 'userfeedback',
            providesTags: (result) => 
            result ?
                [
                    ...result.map(({id}) => ({ type: 'UserFeedback', id: id.toString() } as const)),
                    { type: 'UserFeedback', id: 'LIST' }
                ] : [{ type: 'UserFeedback', id: 'LIST' }],
            transformResponse: ({_embedded}, meta, args) => {
                return _embedded && _embedded.userfeedback ? [..._embedded.userfeedback] : []
            }
        }),
        getFeedbackById: builder.query<UserFeedback, number>({
            query: (id) => `userfeedback/${id}`,
            providesTags: (result, error, arg) => result ? [{ type: 'UserFeedback', id: result.id.toString()}] : []
        }),
        updateFeedback: builder.mutation({
            query: (feedback:UserFeedback) => {
                return {
                    url: `userfeedback/${feedback.id}`,
                    method: 'PATCH',
                    body: {
                        ...feedback
                    }
                }
            },
            invalidatesTags: (result, error, {id}) => [{type: 'UserFeedback', id: id.toString()}]
        })
    }),
    overrideExisting: false
})

export const {
    useLazyGetFeedbackPageQuery,
    useLazyFindFeedbackBySearchTermPageQuery
} = feedbackApi