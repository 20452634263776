import { api } from '../app/api'
import { GetPagedArgs } from '../app/queries';
import Paged from '../types/Paged';
import Schedule from '../types/Schedule'

const scheduleApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getSchedulePage: builder.query<Paged<Schedule>,GetPagedArgs>({
            query: ({pageNumber, pageSize, sortBy, sortDirection}) => {
                let searchParams : URLSearchParams = new URLSearchParams();
                searchParams.set('pageNumber',pageNumber.toString());
                searchParams.set('pageSize',pageSize.toString());

                if (sortBy && sortBy !== "") {
                    searchParams.set('sortBy', sortBy);
                }

                if (sortDirection && sortDirection !== "") {
                    searchParams.set('sortDirection', sortDirection);
                }

                return `schedules/paged?${searchParams.toString()}`;                
            },
            transformResponse: ({_embedded, page}) => {
                const { number, size, totalElements, totalPages } = page
                let pagedResponse : Paged<Schedule> = {
                    items: _embedded && _embedded.schedules ? _embedded.schedules : [],
                    page: number+1,
                    size: size,
                    totalItems: totalElements,
                    totalPages: totalPages
                }

                return pagedResponse
            }            
        }),
        findSchedulesBySearchTermPage: builder.query<Paged<Schedule>, GetPagedArgs>({
            query: ({searchTerm, pageNumber, pageSize, sortBy, sortDirection}) => {
                let searchParams : URLSearchParams = new URLSearchParams();
                searchParams.set('searchTerm',searchTerm);
                searchParams.set('pageNumber',pageNumber.toString());
                searchParams.set('pageSize',pageSize.toString());

                if (sortBy && sortBy !== "") {
                    searchParams.set('sortBy', sortBy);
                }

                if (sortDirection && sortDirection !== "") {
                    searchParams.set('sortDirection', sortDirection);
                }

                return `schedules/search/findByFuzzyCriteria/paged?${searchParams.toString()}`;
            },
            transformResponse: ({_embedded, page}) => {
                const { number, size, totalElements, totalPages } = page
                let pagedResponse : Paged<Schedule> = {
                    items: _embedded && _embedded.schedules ? _embedded.schedules : [],
                    page: number+1,
                    size: size,
                    totalItems: totalElements,
                    totalPages: totalPages
                }

                return pagedResponse
            }            
        }),                 
        getAllSchedules: builder.query<Schedule[],void>({
            query: () => 'schedules',
            providesTags: (result) =>
                result ?
                    [
                        ...result.map(({id}) => ({ type: 'Schedule', id: id.toString()} as const)),
                        { type: 'Schedule', id: 'LIST' },
                    ] :
                    [{ type: 'Schedule', id: 'LIST'}],
            transformResponse: ({ _embedded }) => {
                return _embedded && _embedded.schedules ? [..._embedded.schedules] : []
            }
        }),
        getScheduleById: builder.query<Schedule,number>({
            query: (id) => `schedules/${id}`,
            providesTags: (result) => result ? [{type: 'Schedule', id: result.id.toString()}] : []
        }),
        createSchedule: builder.mutation({
            query: (schedule:Schedule) => {
                return {
                    url: `schedules`,
                    method: 'POST',
                    body: {...schedule}
                }
            },
            invalidatesTags: (result, error, {id}) =>[{type: 'Schedule', id: 'LIST'}]
        }),
        updateSchedule: builder.mutation({
            query: (schedule:Schedule) => {
                return {
                    url: `schedules/${schedule.id}`,
                    method: 'PATCH',
                    body: {...schedule}
                }
            },
            invalidatesTags: (result) => [{type: 'Schedule', id: result.id.toString()}]
        }),
        deleteSchedule: builder.mutation({
            query: ({id}:Schedule) => {
                return {
                    url: `schedules/${id}`,
                    method: 'DELETE'
                }
            },
            invalidatesTags: (result) => [{type: 'Schedule', id: result.id.toString()}]
        })         
    }),
    overrideExisting: false
})

export const {
    useLazyGetSchedulePageQuery,
    useLazyFindSchedulesBySearchTermPageQuery,
    useGetAllSchedulesQuery,
    useGetScheduleByIdQuery,
    useCreateScheduleMutation,
    useUpdateScheduleMutation,
    useDeleteScheduleMutation,
} = scheduleApi