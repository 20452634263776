import { Auth0Client, User, createAuth0Client } from "@auth0/auth0-spa-js";
import { useEffect, useMemo, useState } from "react";
import { AuthProviderContext } from "../app/context";


const AuthContextProvider = (props:any) =>{
    
    const [authClient, setAuthClient] = useState<Auth0Client>({} as Auth0Client)
    const [isAuthClientSet, setIsAuthClientSet] = useState(false)
    
    // useMemo(() => {
    //     createAuth0Client({
    //         domain: 'login.icetimemobile.com',
    //         clientId: 'Qs8Dc8Puvnd9VA7Rh09D1uUpOE52rEt6',
    //         cacheLocation: 'localstorage',
    //       }).then((auth0:Auth0Client) => {
    //         console.log('setting authClient: ',auth0)
    //         setAuthClient(auth0)
    //       });
    // },[authClient])
  
    useEffect(() => {
        createAuth0Client({
            domain: 'login.icetimemobile.com',
            clientId: 'Qs8Dc8Puvnd9VA7Rh09D1uUpOE52rEt6',
            cacheLocation: 'localstorage',
          }).then((auth0:Auth0Client) => {
            //console.log('setting authClient in AuthContextProvider: ',auth0)
            //auth0.getUser().then((user) => console.log('user: ',user))
            setAuthClient(auth0)
            setIsAuthClientSet(true)
          });
    },[isAuthClientSet])

    return (
        <AuthProviderContext.Provider value={authClient}>
            {props.children}
        </AuthProviderContext.Provider>
    )
}

export default AuthContextProvider