import { api } from '../app/api'
import { GetPagedArgs } from '../app/queries';
import Facility from '../types/Facility'
import Organization from '../types/Organization'
import Paged from '../types/Paged';

const organizationApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getOrganizationPage: builder.query<Paged<Organization>,GetPagedArgs>({
            query: ({pageNumber, pageSize, sortBy, sortDirection}) => {
                let searchParams : URLSearchParams = new URLSearchParams();
                searchParams.set('pageNumber',pageNumber.toString());
                searchParams.set('pageSize',pageSize.toString());

                if (sortBy && sortBy !== "") {
                    searchParams.set('sortBy', sortBy);
                }

                if (sortDirection && sortDirection !== "") {
                    searchParams.set('sortDirection', sortDirection);
                }

                return `organizations/paged?${searchParams.toString()}`;                
            },
            transformResponse: ({_embedded, page}) => {
                const { number, size, totalElements, totalPages } = page
                let pagedResponse : Paged<Organization> = {
                    items: _embedded && _embedded.organizations ? _embedded.organizations : [],
                    page: number+1,
                    size: size,
                    totalItems: totalElements,
                    totalPages: totalPages
                }

                return pagedResponse
            }            
        }),
        findOrganizationsBySearchTermPage: builder.query<Paged<Organization>, GetPagedArgs>({
            query: ({searchTerm, pageNumber, pageSize, sortBy, sortDirection}) => {
                let searchParams : URLSearchParams = new URLSearchParams();
                searchParams.set('searchTerm',searchTerm);
                searchParams.set('pageNumber',pageNumber.toString());
                searchParams.set('pageSize',pageSize.toString());

                if (sortBy && sortBy !== "") {
                    searchParams.set('sortBy', sortBy);
                }

                if (sortDirection && sortDirection !== "") {
                    searchParams.set('sortDirection', sortDirection);
                }

                return `organizations/search/findByFuzzyCriteria/paged?${searchParams.toString()}`;
            },
            transformResponse: ({_embedded, page}) => {
                const { number, size, totalElements, totalPages } = page
                let pagedResponse : Paged<Organization> = {
                    items: _embedded && _embedded.organizations ? _embedded.organizations : [],
                    page: number+1,
                    size: size,
                    totalItems: totalElements,
                    totalPages: totalPages
                }

                return pagedResponse
            }            
        }),                
        getAllOrganizations: builder.query<Organization[], void>({
            query: () => `organizations`,
            providesTags: (result) =>
            // is result available?
            result
              ? // successful query
                [
                  ...result.map(({ id }) => ({ type: 'Organization', id: id.toString() } as const)),
                  { type: 'Organization', id: 'LIST' },
                ]
              : // an error occurred, but we still want to refetch this query when `{ type: 'Posts', id: 'LIST' }` is invalidated
                [{ type: 'Organization', id: 'LIST' }],
            transformResponse: ({ _embedded }, meta, arg) => {
                return _embedded && _embedded.organizations ? [..._embedded.organizations] : []    
            }            
        }),
        getOrganizationById: builder.query<Organization, number>({
            query: (id) => `organizations/${id}`,
            providesTags: (result) => result ? [{type: 'Organization', id: result.id.toString()}] : []
        }),
        getOrganizationFacilities: builder.query<Facility[], number>({
            query: (id) => `organizations/${id}/facilities`,
            providesTags: (result) => result && result.length > 0 ? [{type: 'OrgFacility', id: result[0].organizationId.toString()}] : [],
            transformResponse: ({_embedded}, meta, arg) => {
                return _embedded && _embedded.facilities ? [..._embedded.facilities] : []
            }
        }),
        createOrganization: builder.mutation({
            query: (organization:Organization) => {
                const payload = {...organization}
                return {
                    url: `organizations`,
                    method: 'POST',
                    body: payload
                }
            },
            invalidatesTags: (result, error, {id}) => [{type: 'Organization', id: 'LIST'}],
        }),
        updateOrganization: builder.mutation({
            query: (organization:Organization) => {
                const payload = {...organization}
                return {
                    url: `organizations/${organization.id}`,
                    method: 'PATCH',
                    body: payload
                }
            },
            invalidatesTags: (result, error, {id}) => [{type: 'Organization', id: id.toString() }],
        })
    }),
    overrideExisting: false
})

export const {
    useLazyFindOrganizationsBySearchTermPageQuery,
    useLazyGetOrganizationPageQuery,
    useGetAllOrganizationsQuery,
    useGetOrganizationByIdQuery,
    useGetOrganizationFacilitiesQuery,
    useLazyGetOrganizationFacilitiesQuery,
    useCreateOrganizationMutation,
    useUpdateOrganizationMutation,
} = organizationApi