import {Dialog, DialogTitle, Grid} from '@mui/material'
import SessionForm from './SessionForm'
import Session from '../../types/Session'

const SessionFormDialog = (props:any) => {

    const {open, onClose, seriesId, session, afterSave, ...otherProps } = props

    return (
        <Dialog open={open} onClose={onClose} {...otherProps}>
            <DialogTitle>{otherProps.dialogTitle}</DialogTitle>
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{m:2}}>
                    <SessionForm session={ session } afterSave={afterSave}></SessionForm>
                </Grid>
            </Grid>
        </Dialog>
    )
}

export default SessionFormDialog