import { Link, useLocation, useParams } from "react-router-dom"
import Dashboard from "./Dashboard"
import { Box, CircularProgress, Grid, Paper, Tab } from "@mui/material"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import { useState } from "react"

import FacilityInfoPanel from "../components/facility/FacilityInfoPanel"
import FacilityForm from "../components/facility/FacilityForm"
import { useGetFacilityByIdQuery } from "../services/facilityApi"
import Facility from "../types/Facility"
import FacilitySeriesGrid from "../components/facility/FacilitySeriesGrid"

const FacilityDetailPage = () => {
    const {id} = useParams()
    const location = useLocation()
    const {data:facility, isLoading:loadingFac, error:facError, refetch:refetchFac} = useGetFacilityByIdQuery(parseInt(id ?? "0"))
    const [selectedTab, setSelectedTab] = useState('info')
    const handleTabChange = (_evt: React.SyntheticEvent, tabValue: string) => {
      setSelectedTab(tabValue)
    }
    // const handleBackClick = (evt:any) => {
    //   evt.preventDefault();
    //   globalThis.history.back();
    // }

    return (
        <Dashboard>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                    <Link to={location.state.prevPage} state={location.state}>&lt;- Back</Link>
                    { loadingFac 
                        ? (<CircularProgress/>) 
                        : (
                            <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={selectedTab}>
                              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                                  <Tab label="Info" value="info" />
                                  <Tab label="Update" value="update" />
                                </TabList>
                              </Box>
                              <TabPanel value="info"><FacilityInfoPanel facility={facility}/></TabPanel>
                              <TabPanel value="update"><FacilityForm facility={facility ?? {} as Facility} organizationId={facility?.organizationId} afterSave={refetchFac}/></TabPanel>
                            </TabContext>
                          </Box>
                        ) 
                    }
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                    { loadingFac
                        ? (<CircularProgress/>)
                        : (<FacilitySeriesGrid organizationId={facility?.organizationId} facilityId={facility?.id} showSeriesOnly={false} />)}
                    </Paper>
                </Grid>                
            </Grid>
        </Dashboard>
    )

}

export default FacilityDetailPage