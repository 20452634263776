import { useParams } from "react-router-dom"
import { useGetUserByIdQuery } from "../services/userApi"
import Dashboard from "./Dashboard"
import { Box, CircularProgress, Grid, Paper, Tab } from "@mui/material"
import UserInfoPanel from "../components/user/UserInfoPanel"
//import OrganizationFacilityGrid from "../components/organization/OrganizationFacilityGrid"
import { TabContext, TabList, TabPanel } from "@mui/lab"
//import OrganizationForm from "../components/organization/OrganizationForm"
import { useState } from "react"
import UserDemographicPanel from "../components/user/UserDemographicPanel"

const OrganizationDetailPage = () => {
    const {id} = useParams()
    const {data:user, isLoading:loadingUser, error:orgError, refetch:refetchUser} = useGetUserByIdQuery(parseInt(id ?? "0"))
    const [selectedTab, setSelectedTab] = useState('info')
    const handleTabChange = (_evt: React.SyntheticEvent, tabValue: string) => {
      setSelectedTab(tabValue)
    }    

    return (
        <Dashboard>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                    { loadingUser 
                        ? (<CircularProgress/>) 
                        : (
                            <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={selectedTab}>
                              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                                  <Tab label="Info" value="info" />
                                  <Tab label="Demographics" value="demo" />
                                  {/* <Tab label="Update" value="update" /> */}
                                </TabList>
                              </Box>
                              <TabPanel value="info"><UserInfoPanel user={user}/></TabPanel>
                              <TabPanel value="demo"><UserDemographicPanel user={user}/></TabPanel>
                              {/* <TabPanel value="update"><OrganizationForm organization={organization} afterSave={refetchOrg}/></TabPanel> */}
                            </TabContext>
                          </Box>
                        ) 
                    }
                    </Paper>
                </Grid>
                {/* <Grid item xs={12}>
                    <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                    { loadingOrg
                        ? (<CircularProgress/>)
                        : (<OrganizationFacilityGrid organizationId={organization?.id}/>)}
                    </Paper>
                </Grid> */}
            </Grid>
        </Dashboard>
    )

}

export default OrganizationDetailPage