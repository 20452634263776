import { Auth0Client, User, createAuth0Client } from '@auth0/auth0-spa-js';
import { Button, CircularProgress, Grid, Stack, Typography, colors } from '@mui/material';
import { useEffect, useState } from 'react';
import { createAuthClient } from '../app/security';
import { useAuthProvider } from '../app/context';
import { useNavigate } from 'react-router';

type LoginProps = { logout?:boolean }
const Login = ({logout}:LoginProps) => {

    //const [authClient, setAuthClient] = useState({} as Auth0Client)
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const authClient = useAuthProvider()
    const navigate = useNavigate()

    useEffect(() => {
        if (authClient && typeof authClient.isAuthenticated == "function") {
            authClient.isAuthenticated().then((auth) => setIsAuthenticated(auth))
        }

        if (isAuthenticated) {
            navigate('/organizations')
        }

    },[authClient,isAuthenticated])
      
    const handleLoginClick = async () => {
          try {
            await authClient.loginWithPopup()

            const user = await authClient.getUser();
            if (user) { setIsAuthenticated(true) }
          
            //const accessToken = await authClient.getTokenSilently()
          } catch (error) {
            console.log(error)
          }
    }

    const handleLogoutClick = async () => {
        await authClient.logout()
        setIsAuthenticated(false)
    }

    if (logout) {
        handleLogoutClick()
    }    

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Stack direction="column" justifyContent="center" alignItems="center">
                <>
                {
                    !isAuthenticated ? 
                                (<><Button color="primary" variant="contained" onClick={handleLoginClick} sx={{m: 1, alignSelf: 'center'}}>
                                        Login
                                    </Button></>) : (<><Button color="primary" variant="contained" onClick={handleLogoutClick} sx={{m: 1, alignSelf: 'center'}}>
                                        Logout
                                    </Button></>)
                }
                
                </>           
                </Stack>
            </Grid>             
        </Grid>
    )
}

export default Login