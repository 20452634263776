import { Grid, Paper } from '@mui/material'
import Login from "../components/Login";
import Image from "mui-image"

type LoginPageProps = {logout?:boolean}
const LoginPage = ({logout}:LoginPageProps) => {

    return (
            <Grid container spacing={3} sx={{ display: "flex", flexDirection: "row", alignItems:"center", justifyContent:"center"}}>
              <Grid item xs={12} md={6}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column", alignItems:"center", justifyContent:"center" }}>
                    <Image src="../../itm_logo.png" style={{ maxWidth: 256 }}></Image>
                    <Login logout={logout} />
                </Paper>
              </Grid>
            </Grid>       
    )
}

export default LoginPage