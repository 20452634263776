
import { useState } from "react"
import Organization from "../../types/Organization"
import * as Yup from 'yup'
import { FormikErrors, useFormik } from 'formik'
import { useCreateOrganizationMutation, useUpdateOrganizationMutation } from "../../services/organizationApi"
import { Alert, AlertColor, AlertPropsColorOverrides, Button, Grid, Stack, TextField } from "@mui/material"
import { useAuthProvider } from "../../app/context"

type OrganizationFormProps = { organization?:Organization, afterSave?:Function }
const OrganizationForm = ({organization, afterSave}:OrganizationFormProps) => {
    const [showAlert, setShowAlert] = useState(false)
    const [alertSeverity, setAlertSeverity] = useState<any>('success')
    const [alertContent, setAlertContent] = useState('')
    const [createOrganization] = useCreateOrganizationMutation()
    const [updateOrganization] = useUpdateOrganizationMutation()
    const authProvider = useAuthProvider()
    
    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .min(5, 'Name must be at least 5 characters')
            .max(50, 'Name may not be more than 50 characters')
            .required('Name is required'),
        websiteUrl: Yup.string()
            .optional()
            .max(128, 'Website URL may not be more than 128 characters'),
        logoUrl: Yup.string()
            .optional()
            .max(128, 'Logo URL may not be more than 128 characters')
    })

    const formik = useFormik({
        initialValues: organization as Organization,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => handleSubmit(values)
    })

    const handleSubmit = async (values:any) => {
        
        setShowAlert(false)
        setAlertContent('Saving...')
        setAlertSeverity('info')
        setShowAlert(true)

        const updateFn = (organization?.id && organization?.id > 0 ? updateOrganization : createOrganization)

        try {
            let updatedOrg = await updateFn(await mapValuesToOrganization(values)).unwrap()
            formik.setValues(updatedOrg)
            setAlertContent(`Organization ${updatedOrg.name} saved.`)
            setAlertSeverity('success')
            setShowAlert(true)
            afterSave && afterSave()
        } catch (error:any) {
            formik.setErrors(error)
            setAlertContent('Error saving organization.')
            setAlertSeverity('error')
            setShowAlert(true)
        }
    }

    const mapValuesToOrganization = async (values:any) : Promise<Organization> => {
        const updateUser = await authProvider.getUser();
        const updateUsername = updateUser.email ?? updateUser.preferred_username

        let org = {
            id: values.id,
            name: values.name,
            websiteUrl: values.websiteUrl,
            logoUrl: values.logoUrl,
            lastModified: new Date(),
            modifiedBy: updateUsername,
            status: 'ACTIVE'
        } as Organization

        return org;
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={1}>
            {showAlert ? (
                <Grid item xs={12}>
                    <Alert severity={alertSeverity}>{alertContent}</Alert>    
                </Grid>
            ) : (<></>)}
                <Grid item xs={12}>
                    <TextField
                        sx={{m: 1}}
                        id="name"
                        name="name"
                        label="Organization Name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        sx={{m: 1}}
                        id="websiteUrl"
                        name="websiteUrl"
                        label="Website URL"
                        value={formik.values.websiteUrl}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.websiteUrl && Boolean(formik.errors.websiteUrl)}
                        helperText={formik.touched.websiteUrl && formik.errors.websiteUrl}/>                    
                </Grid> 
                <Grid item xs={12}>
                    <TextField
                        sx={{m: 1}}
                        id="logoUrl"
                        name="logoUrl"
                        label="Logo URL"
                        value={formik.values.logoUrl}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.logoUrl && Boolean(formik.errors.logoUrl)}
                        helperText={formik.touched.logoUrl && formik.errors.logoUrl}/>                    
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="end">
                        <Button color="primary" variant="contained" type="submit" sx={{m: 1, alignSelf: 'right'}}>
                        {formik.values.id ? ('Update') : ('Create')}
                        </Button>
                    </Stack>
                </Grid>                                                                 
            </Grid>
        </form>
    )

}

export default OrganizationForm