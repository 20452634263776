import { useParams } from "react-router-dom"
import Dashboard from "./Dashboard"
import { Box, CircularProgress, Grid, Paper, Tab } from "@mui/material"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import { useState } from "react"

import SeriesForm from "../components/series/SeriesForm"
import { useGetSeriesByIdQuery, useGetSeriesSessionsQuery } from "../services/seriesApi"
import Series from "../types/Series"
import SeriesInfoPanel from "../components/series/SeriesInfoPanel"
import SessionGrid from "../components/session/SessionGrid"

const SeriesDetailPage = () => {
    const {id} = useParams()
    const {data:series, isLoading:seriesLoading, error:seriesError, refetch:refetchSeries} = useGetSeriesByIdQuery(parseInt(id ? id : "0"))
    const {data:sessions, isLoading:sessionsLoading, error:sessionError, refetch:refetchSessions} = useGetSeriesSessionsQuery(parseInt(id ? id : series?.id.toString() ? series?.id.toString() : "0"))

    const onSeriesSaved = async () => {
      await refetchSeries()
      await refetchSessions()
    }

    const [selectedTab, setSelectedTab] = useState('info')
    const handleTabChange = (_evt: React.SyntheticEvent, tabValue: string) => {
      setSelectedTab(tabValue)
    }    

    return (
        <Dashboard>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                    { seriesLoading 
                        ? (<CircularProgress/>) 
                        : (
                            <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={selectedTab}>
                              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                                  <Tab label="Info" value="info" />
                                  <Tab label="Update" value="update" />
                                </TabList>
                              </Box>
                              <TabPanel value="info"><SeriesInfoPanel series={series} /></TabPanel>
                              <TabPanel value="update">
                                <SeriesForm 
                                  series={series ?? {} as Series}
                                  organizationId={series?.organizationId}
                                  facilityId={series?.facilityId}
                                  scheduleId={series?.scheduleId} 
                                  afterSave={onSeriesSaved}/></TabPanel>
                            </TabContext>
                          </Box>
                        ) 
                    }
                    </Paper>
                </Grid>
                { selectedTab == 'info' ? <Grid item xs={12}>
                    <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                    { sessionsLoading
                        ? (<CircularProgress/>)
                        : (<SessionGrid sessions={sessions} seriesId={series?.id} scheduleId={series?.scheduleId} afterSave={refetchSessions} />)}
                    </Paper>
                </Grid> : <></> }
            </Grid>
        </Dashboard>
    )

}

export default SeriesDetailPage