import React from "react"
import { createBrowserRouter } from "react-router-dom"
import OrganizationPage from "../pages/OrganizationPage"
import OrganizationDetailPage from "../pages/OrganizationDetailPage"
import FacilityPage from "../pages/FacilityPage"
import FacilityDetailPage from "../pages/FacilityDetailPage"
import SchedulePage from '../pages/SchedulePage'
import ScheduleDetailPage from "../pages/ScheduleDetailPage"
import SeriesPage from '../pages/SeriesPage'
import SeriesDetailPage from "../pages/SeriesDetailPage"
import LoginPage from "../pages/LoginPage"
import UserPage from "../pages/UserPage"
import UserDetailPage from "../pages/UserDetailPage"
import ProtectedRoute from "../components/ProtectedRoute"
import { createAuth0Client } from "@auth0/auth0-spa-js"
import FeedbackPage from "../pages/FeedbackPage"
import { createAuthClient } from "./security"

const isAuthenticated = async () => {
    const authClient = await createAuthClient()

    const auth = await authClient.isAuthenticated()

    return auth

    //   }).then((auth0:Auth0Client) => {
    //     //console.log('setting authClient in AuthContextProvider: ',auth0)
    //     //auth0.getUser().then((user) => console.log('user: ',user))
    //     setAuthClient(auth0)
    //     setIsAuthClientSet(true)
    //   });    
}

export const router = createBrowserRouter([
{
    path: "/",
    element: <LoginPage />,
    index: true
}, {
    element: <ProtectedRoute isAuthenticated={isAuthenticated} />,
    children: [
        {
            path: "/login",
            element: <LoginPage />
        }, {
            path: "/organizations",
            element: <OrganizationPage />
        }, {
            path: "/organizations/:id?",
            element: <OrganizationDetailPage />
        }, {
            path: "/facilities",
            element: <FacilityPage />
        }, {
            path: "/facilities/:id?",
            element: <FacilityDetailPage />
        }, {
            path: "/schedules",
            element: <SchedulePage />
        }, {
            path: "/schedules/:id?",
            element: <ScheduleDetailPage />
        }, {
            path: "/series",
            element: <SeriesPage />
        }, {
            path: "/series/:id?",
            element: <SeriesDetailPage />
        }, {
            path: "/users",
            element: <UserPage />
        }, {
            path: "/users/:id?",
            element: <UserDetailPage />
        }, {
            path: "/feedback",
            element: <FeedbackPage />
        }, {
            path: "/logout",
            element: <LoginPage logout={true} />
        }
    ]
} 
])
