import {Dialog, DialogTitle, Grid} from '@mui/material'
import ScheduleForm from './ScheduleForm'
import Schedule from '../../types/Schedule'

const ScheduleFormDialog = (props:any) => {

    const {open, onClose, organizationId, ...otherProps } = props

    return (
        <Dialog open={open} onClose={onClose} {...otherProps}>
            <DialogTitle>New Schedule</DialogTitle>
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{m:2}}>
                    <ScheduleForm schedule={ otherProps.schedule ?? {} as Schedule }></ScheduleForm>
                </Grid>
            </Grid>
        </Dialog>
    )
}

export default ScheduleFormDialog