import {Button, Dialog, DialogTitle, Grid, Stack} from '@mui/material'
import SeriesForm from './SeriesForm'
import Series from '../../types/Series'
import { useState } from 'react'

const SeriesFormDialog = (props:any) => {

    const {open, onClose, series, cloneSeries, organizationId, facilityId, scheduleId, afterSave, ...otherProps } = props

    return (
        <Dialog open={open} onClose={onClose} {...otherProps}>
            <Stack direction="row" justifyContent="space-between">
            <DialogTitle>{ series && series.id > 0 ? "Update Series" : "New Series" }</DialogTitle>
            { series && series.id ? <Button color="primary" variant="contained" onClick={cloneSeries}>Clone</Button> : <></> }
            </Stack>
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{m:2}}>
                    <SeriesForm series={ series ?? {} as Series } organizationId={organizationId} facilityId={facilityId} scheduleId={scheduleId} afterSave={afterSave}></SeriesForm>
                </Grid>
            </Grid>
        </Dialog>
    )
}

export default SeriesFormDialog