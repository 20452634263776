import { useParams } from "react-router-dom"
import { useGetOrganizationFacilitiesQuery } from "../../services/organizationApi"
import { CircularProgress } from "@mui/material"
import FacilityGrid from "../facility/FacilityGrid"

type OrgFacilityGridProps = { organizationId?:number }
const OrganizationFacilityGrid = ({organizationId}:OrgFacilityGridProps) => {
    const {data:facilities, isLoading, error, refetch:refetchFacilities} = useGetOrganizationFacilitiesQuery(organizationId ?? 0)

    return (
        isLoading 
        ? (<CircularProgress/>)
        : (<FacilityGrid facilities={facilities ?? []} allowNewFacility={true} showSearchBox={false} organizationId={organizationId} afterSave={refetchFacilities}/>)
    )
}

export default OrganizationFacilityGrid