import { CircularProgress, Grid, Typography } from "@mui/material"
import Facility from "../../types/Facility"
import Title from "../Title"
import { useGetOrganizationByIdQuery } from "../../services/organizationApi"
import { defaultDateTimeOptions } from "../../app/constants"
import FacilityMap from "./FacilityMap"

type FacInfoPanelProps = {facility?:Facility}
const FacilityInfoPanel = ({facility}:FacInfoPanelProps) => {
const {data:organization, isLoading:loadingOrg} = useGetOrganizationByIdQuery(facility?.organizationId ?? 0)

    return (
        <Grid container spacing={1} justifyContent="space-between">
            <Grid item xs={12} md={6}>
                <Grid container spacing={1} xs={12}>
                    <Grid item xs={12}>
                        <Title>{facility?.name}</Title>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" color="text.secondary" sx={{borderBottom: 1, borderColor: 'divider', m:1}}>
                            Contact
                        </Typography>
                    </Grid>
                    { facility?.websiteUrl ?            
                    (<Grid item xs={12}>
                    <Typography color="text.secondary" sx={{ flex: 1 }}>
                                <a href={facility?.websiteUrl} target="_blank" rel="noreferrer">{facility?.websiteUrl}</a>
                    </Typography>    
                    </Grid>) : (<></>) }
                    { facility?.phoneNumber ?            
                    (<Grid item xs={12}>
                    <Typography color="text.secondary" sx={{ flex: 1 }}>
                                <a href={`tel:${facility?.phoneNumber}`}>{facility?.phoneNumber}</a>
                    </Typography>    
                    </Grid>) : (<></>) }                        
                    <Grid item xs={12}>
                        <Typography variant="h6" color="text.secondary" sx={{borderBottom: 1, borderColor: 'divider', m:1}}>
                            Organization
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {loadingOrg ?
                            (<CircularProgress size={12}/>) : 
                            (<Typography variant="body1">{organization?.name}</Typography>)}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" color="text.secondary" sx={{borderBottom: 1, borderColor: 'divider', m:1}}>
                            Address
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">{facility?.address.line1}</Typography>
                    </Grid>
                    { facility?.address.line2 ? 
                        (<Grid item xs={12}>
                            <Typography variant="body1">{facility?.address.line2}</Typography>
                        </Grid>) : (<></>)}
                    <Grid item xs={12}>
                        <Typography variant="body1">{facility?.address.city}, {facility?.address.stateProvince} {facility?.address.postalCode} {facility?.address.country}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" color="text.secondary" sx={{borderBottom: 1, borderColor: 'divider', m:1}}>
                            Last Modified
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">{new Date(facility?.lastModified).toLocaleString('en-US', defaultDateTimeOptions.dateTimeOptions)} by {facility?.modifiedBy}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
                <Grid container spacing={1} xs={12}>
                    <Grid item xs={12}>
                    <FacilityMap facility={facility}></FacilityMap>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default FacilityInfoPanel