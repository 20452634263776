import { CircularProgress, Grid, Typography } from "@mui/material"
import Facility from "../../types/Facility"
import Title from "../Title"
import { useGetOrganizationByIdQuery } from "../../services/organizationApi"
import Series from "../../types/Series"
import { defaultDateTimeOptions } from "../../app/constants"

type SeriesInfoPanelProps = {series?:Series}
const SeriesInfoPanel = ({series}:SeriesInfoPanelProps) => {

    const startDate = new Date(series?.startDate + ' ' + series?.startTime).toLocaleDateString('en-US', defaultDateTimeOptions.dateOptions)
    const endDate = new Date(series?.endDate + ' ' + series?.endTime).toLocaleDateString('en-US', defaultDateTimeOptions.dateOptions)

    const startTime = new Date(series?.startDate + ' ' + series?.startTime).toLocaleTimeString('en-US', defaultDateTimeOptions.timeOptions)
    const endTime = new Date(series?.startDate + ' ' + series?.endTime).toLocaleTimeString('en-US', defaultDateTimeOptions.timeOptions)

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Title>{series?.seriesName}</Title>
            </Grid>
            <Grid item xs={12} md={3}>
                <Typography variant="h6" color="text.secondary" sx={{borderBottom: 1, borderColor: 'divider', m:1}}>
                    Organization
                </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
                <Typography variant="body1">{series?.organizationName}</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
                <Typography variant="h6" color="text.secondary" sx={{borderBottom: 1, borderColor: 'divider', m:1}}>
                    Facility
                </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
                <Typography variant="body1">{series?.facilityName}</Typography>
            </Grid>                         
            <Grid item xs={12} md={3}>
                <Typography variant="h6" color="text.secondary" sx={{borderBottom: 1, borderColor: 'divider', m:1}}>
                    Dates
                </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
                <Typography variant="body1">{startDate} - {endDate}</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
                <Typography variant="h6" color="text.secondary" sx={{borderBottom: 1, borderColor: 'divider', m:1}}>
                    Times
                </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
                <Typography variant="body1">{startTime} - {endTime}</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
                <Typography variant="h6" color="text.secondary" sx={{borderBottom: 1, borderColor: 'divider', m:1}}>
                    Registration
                </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
                <Typography variant="body1">
                    <a href={series?.registrationUrl} target="_blank">{series?.registrationUrl}</a>
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" color="text.secondary" sx={{borderBottom: 1, borderColor: 'divider', m:1}}>
                    Last Modified
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1">{new Date(series?.lastModified).toLocaleString('en-US', defaultDateTimeOptions.dateTimeOptions)} by {series?.modifiedBy}</Typography>
            </Grid>                        
        </Grid>
    )
}

export default SeriesInfoPanel