import { api } from '../app/api'
import { GetPagedArgs } from '../app/queries'
import Facility from '../types/Facility'
import Paged from '../types/Paged'

const facilityApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getFacilityPage: builder.query<Paged<Facility>,GetPagedArgs>({
            query: ({pageNumber, pageSize, sortBy, sortDirection}) => {
                let searchParams : URLSearchParams = new URLSearchParams();
                searchParams.set('pageNumber',pageNumber.toString());
                searchParams.set('pageSize',pageSize.toString());

                if (sortBy && sortBy !== "") {
                    searchParams.set('sortBy', sortBy);
                }

                if (sortDirection && sortDirection !== "") {
                    searchParams.set('sortDirection', sortDirection);
                }

                return `facilities/paged?${searchParams.toString()}`;                
            },
            transformResponse: ({_embedded, page}) => {
                const { number, size, totalElements, totalPages } = page
                let pagedResponse : Paged<Facility> = {
                    items: _embedded && _embedded.facilities ? _embedded.facilities : [],
                    page: number+1,
                    size: size,
                    totalItems: totalElements,
                    totalPages: totalPages
                }

                return pagedResponse
            }            
        }),
        findFacilitiesBySearchTermPage: builder.query<Paged<Facility>, GetPagedArgs>({
            query: ({searchTerm, pageNumber, pageSize, sortBy, sortDirection}) => {
                let searchParams : URLSearchParams = new URLSearchParams();
                searchParams.set('searchTerm',searchTerm);
                searchParams.set('pageNumber',pageNumber.toString());
                searchParams.set('pageSize',pageSize.toString());

                if (sortBy && sortBy !== "") {
                    searchParams.set('sortBy', sortBy);
                }

                if (sortDirection && sortDirection !== "") {
                    searchParams.set('sortDirection', sortDirection);
                }

                return `facilities/search/findByFuzzyCriteria/paged?${searchParams.toString()}`;
            },
            transformResponse: ({_embedded, page}) => {
                const { number, size, totalElements, totalPages } = page
                let pagedResponse : Paged<Facility> = {
                    items: _embedded && _embedded.facilities ? _embedded.facilities : [],
                    page: number+1,
                    size: size,
                    totalItems: totalElements,
                    totalPages: totalPages
                }

                return pagedResponse
            }            
        }),        
        getAllFacilities: builder.query<Facility[],void>({
            query: () => 'facilities',
            providesTags: (result) =>
            // is result available?
            result
              ? // successful query
                [
                  ...result.map(({ id }) => ({ type: 'Facility', id: id.toString() } as const)),
                  { type: 'Facility', id: 'LIST' },
                ]
              : // an error occurred, but we still want to refetch this query when `{ type: 'Posts', id: 'LIST' }` is invalidated
                [{ type: 'Facility', id: 'LIST' }],
            transformResponse: ({ _embedded }, meta, arg) => {
                return _embedded && _embedded.facilities ? [..._embedded.facilities] : []    
            }              
        }),
        getFacilityById: builder.query<Facility, number>({
            query: (id) => `facilities/${id}`,
            providesTags: (result, error, arg) => result ? [{type: 'Facility', id: result.id.toString()}] : []
        }),
        createFacility: builder.mutation({
            query: (facility:Facility) => {
                return {
                    url: `facilities`,
                    method: 'POST',
                    body: {...facility}
                }
            },
            invalidatesTags: (result, error, {id, organizationId}) =>[{type: 'Facility', id: 'LIST'},{type: 'OrgFacility', id: result.organizationId.toString()}]
        }),
        updateFacility: builder.mutation({
            query: (facility:Facility) => {
                return {
                    url: `facilities/${facility.id}`,
                    method: 'PATCH',
                    body: {...facility}
                }
            },
            invalidatesTags: (result, error, {id}) => [{type: 'Facility', id: id.toString()}]
        })
    }),
    overrideExisting: false
})

export const {
    useLazyGetFacilityPageQuery,
    useLazyFindFacilitiesBySearchTermPageQuery,
    useGetAllFacilitiesQuery,
    useLazyGetAllFacilitiesQuery,
    useGetFacilityByIdQuery,
    useCreateFacilityMutation,
    useUpdateFacilityMutation,
} = facilityApi