import React, { useEffect, useMemo, useState } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useAuthProvider } from '../app/context';

type ProtectedRouteProps = {isAuthenticated:()=>Promise<boolean>}
const ProtectedRoute = ({isAuthenticated}:ProtectedRouteProps) => {
const navigate = useNavigate()  
//   if (!isAuthenticated) {
//     return <Navigate to="/" replace />;
//   }

useEffect(() => {
    (async () => {
        const auth = await isAuthenticated()
        if (!auth) {
            navigate('/', { replace: true })
        }
    })()
},[isAuthenticated,navigate])

  return <Outlet />;
};

export default ProtectedRoute;