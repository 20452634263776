import { api } from '../app/api'
import { apiConstants } from '../app/constants'
import { FindSessionsBySeriesArgs } from '../app/queries'
import Session from '../types/Session'

const sessionApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getSessionById: builder.query<Session,number>({
            query: (id) => `sessions/${id}`,
            providesTags: (result) => result ? [{type: 'Session', id: result.id.toString()}] : []
        }),
        findUpcomingBySeriesId: builder.query<Session[], FindSessionsBySeriesArgs>({
            query: ({seriesId,showSeriesOnly}) => `sessions/search/findUpcomingBySeriesId?series_id=${seriesId}&show_series_only=${showSeriesOnly}`,
            providesTags: (result) =>
              result ? [
                ...result.map(({id, seriesId}) => ({ type: 'Session', id: id.toString(), seriesId: seriesId.toString() } as const)),
              ] : [],
            transformResponse: ({ _embedded }, meta, arg) => {
              return _embedded && _embedded.sessions ? _embedded.sessions.map((sesh: Session) => sesh) : []
            } 
          }),         
        createSession: builder.mutation({
            query: (session:Session) => {
                return {
                    url: `sessions`,
                    method: 'POST',
                    body: {
                        ...session,
                        series: `${apiConstants.BASE_URL}/series/${session.seriesId}`
                    }
                }
            },
            invalidatesTags: (result, error, {id}) => [{type: 'SeriesSessions', id: result.seriesId.toString()}]
        }),
        updateSession: builder.mutation({
            query: (session:Session) => {
                return {
                    url: `sessions/${session.id}`,
                    method: 'PATCH',
                    body: {
                        ...session,
                        series: `${apiConstants.BASE_URL}/series/${session.seriesId}`
                    }
                }
            },
            invalidatesTags: (result, error, {id}) => [{type: 'Session', id: id.toString()}]
        }),
        deleteSession: builder.mutation({
            query: (session:Session) => {
                return {
                    url: `sessions/${session.id}`,
                    method: 'DELETE'
                }
            },
            invalidatesTags: (result, error, {id}) => [{type: 'SeriesSessions', id: result.seriesId.toString()}]
        })
    })
})

export const {
    useGetSessionByIdQuery,
    useFindUpcomingBySeriesIdQuery,
    useLazyFindUpcomingBySeriesIdQuery,
    useCreateSessionMutation,
    useUpdateSessionMutation,
    useDeleteSessionMutation,
} = sessionApi