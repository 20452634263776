import React, { useState } from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import Title from "../../components/Title"
import { Link } from "react-router-dom"
import { Box, Button, Stack, TableSortLabel, TextField } from "@mui/material"
import Schedule from "../../types/Schedule"
import ScheduleFormDialog from "./ScheduleFormDialog"
import { useDeleteScheduleMutation } from "../../services/scheduleApi"
import ColumnSort from "../../types/ColumnSort"
import { visuallyHidden } from "@mui/utils"
import { dayOfWeekOptions } from "../../app/constants"

type ScheduleGridProps = { schedules?:Schedule[], afterSave?:Function, handleSearch?:Function, sort?:ColumnSort, handleSort?:Function }
const ScheduleGrid = ({schedules,afterSave,handleSearch,sort,handleSort}:ScheduleGridProps) => {

    const [openDialog,setOpenDialog] = useState(false)
    const [clonedSchedule, setClonedSchedule] = useState({} as unknown as Schedule)
    const [deleteSchedule] = useDeleteScheduleMutation()
    const [searchTerm, setSearchTerm] = useState<string|undefined>(undefined)

    const openFormDialog = () => {
      setOpenDialog(true)
    }
  
    const closeFormDialog = () => {
      setOpenDialog(false)
    }

    const onSearchSchedules = (evt:any) => {
        setSearchTerm(evt.currentTarget.value.toLowerCase())
        handleSearch && handleSearch(evt.currentTarget.value.toLowerCase())        
    }

    const handleDeleteSchedule = async (schedule:Schedule) => {
        // TODO: confirmation dialog
        await deleteSchedule(schedule)
        afterSave && afterSave()
    }

    const handleCloneSchedule = (schedule:Schedule) => {
        setClonedSchedule({
            label: schedule.label,
            startTime: schedule.startTime,
            endTime: schedule.endTime,
            daysOfWeek: schedule.daysOfWeek
        } as Schedule)

        openFormDialog()
    }

    const handleNewSchedule = () => {
        setClonedSchedule({} as Schedule)
        openFormDialog()
    }
    
    return (
        <div className="App">
            <Stack direction="row" justifyContent="space-between" spacing={3}>
                <Title>Schedules</Title>
                <Stack direction="row" justifyContent="flex-end">
                <TextField size="small" style={{ marginRight: 15 }} name="series-search" id="series-search" onChange={onSearchSchedules} placeholder="Search"></TextField>
                <Button color="primary" variant="contained" onClick={handleNewSchedule}>New Schedule</Button>
                </Stack>
            </Stack>

            { <>
                <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                    <TableCell>
                        <TableSortLabel
                            active={ sort?.sortBy === "label"}
                            direction={sort?.sortBy === "label" ? sort?.sortDirection : 'asc'}
                            onClick={() => handleSort && handleSort({
                                sortBy: "label",
                                sortDirection: sort.sortBy === "label" && sort.sortDirection === 'asc' ? 'desc' : 'asc'
                            }) }>
                                Name
                                {sort?.sortBy === "label" ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {sort?.sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>) : null}
                        </TableSortLabel>
                        </TableCell>
                        <TableCell>
                        <TableSortLabel
                            active={ sort?.sortBy === "time"}
                            direction={sort?.sortBy === "time" ? sort?.sortDirection : 'asc'}
                            onClick={() => handleSort && handleSort({
                                sortBy: "time",
                                sortDirection: sort.sortBy === "time" && sort.sortDirection === 'asc' ? 'desc' : 'asc'
                            }) }>
                                Times
                                {sort?.sortBy === "time" ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {sort?.sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>) : null}
                        </TableSortLabel>
                        </TableCell>
                        <TableCell>Days of Week</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {schedules ? ([...schedules].map((row) => (
                        <TableRow
                        key={row.id}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                        <TableCell component="th" scope="row">
                            <Link to={`/schedules/${row.id}`}>{row.label}</Link>
                        </TableCell>
                        <TableCell>{new Date(new Date().toDateString() + ' ' + row.startTime).toLocaleTimeString()} - {new Date(new Date().toDateString() + ' ' + row.endTime).toLocaleTimeString()}</TableCell>
                        <TableCell>{row.daysOfWeek ? row.daysOfWeek.map(d => dayOfWeekOptions.find(opt => opt.value === d).label).join(', ') : ''}</TableCell>
                        <TableCell>
                        <Stack direction="row" justifyContent="flex-end">
                                <Button sx={{m:1}} color="primary" variant="contained" onClick={() => handleCloneSchedule(row)}>Clone</Button>
                                <Button sx={{m:1}} color="error" variant="contained" data-id={row.id} onClick={() => handleDeleteSchedule(row)}>Delete</Button> 
                            </Stack>                               
                        </TableCell>
                        </TableRow>
                    ))) : (
                        <TableRow
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                        <TableCell colSpan={4}>No results.</TableCell>
                        </TableRow>
                    )}
                    </TableBody>
                </Table>
                </TableContainer>
                <ScheduleFormDialog schedule={clonedSchedule} open={openDialog} onClose={closeFormDialog} fullWidth={true} maxWidth="md" />
                </>}                          
        </div>
    )

}

export default ScheduleGrid