import { Auth0Client, createAuth0Client } from '@auth0/auth0-spa-js';

export const createAuthClient = async () => {
    const client = await createAuth0Client({
        domain: 'login.icetimemobile.com',
        clientId: 'Qs8Dc8Puvnd9VA7Rh09D1uUpOE52rEt6',
        cacheLocation: 'localstorage',
        authorizationParams: {
            audience: 'https://api.icetime.app',
            scope: `openid profile email 
                    read:organizations update:organizations delete:organizations
                    read:facilities update:facilities delete:facilities
                    read:series update:series delete:series
                    read:sessions update:sessions delete:sessions
                    read:schedules update:schedules delete:schedules
                    read:users update:users delete:users read:userfeedback
                    update:userfeedback delete:userfeedback`
        }
    })

    return client;
}