import React, { useState } from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import Title from "../Title"
import { Link } from "react-router-dom"
import { Box, Button, Stack, TableSortLabel, TextField } from "@mui/material"
import Facility from "../../types/Facility"
import FacilityFormDialog from "./UserFormDialog"
import User from "../../types/User"
import ColumnSort from "../../types/ColumnSort"
import {visuallyHidden} from "@mui/utils"
import { defaultDateTimeOptions } from "../../app/constants"


type UserGridProps = { users?:User[], allowNewUser?:Boolean, organizationId?:number, handleSearch?:Function, sort?:ColumnSort, handleSort?:Function }
const UserGrid = (props:UserGridProps) => {
    
    const { users, allowNewUser, organizationId, handleSearch, sort, handleSort } = props

    const [openDialog,setOpenDialog] = useState(false)
    const [searchTerm, setSearchTerm] = useState<string|undefined>(undefined)

    const onSearchUsers = (evt:any) => {
        setSearchTerm(evt.currentTarget.value.toLowerCase())
        handleSearch && handleSearch(evt.currentTarget.value.toLowerCase())        
    }

    const openFormDialog = () => {
      setOpenDialog(true)
    }
  
    const closeFormDialog = () => {
      setOpenDialog(false)
    }    

    return (
        <div className="App">
            <Stack direction="row" justifyContent="space-between" spacing={3}>
                <Title>Users</Title>
                <Stack direction="row" justifyContent="flex-end">
                <TextField size="small" style={{ marginRight: 15 }} name="series-search" id="series-search" onChange={onSearchUsers} placeholder="Search"></TextField>
                { allowNewUser && <Button color="primary" variant="contained" onClick={openFormDialog}>New User</Button> }
                </Stack>
            </Stack>

            { <>
                <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                    <TableCell>
                        <TableSortLabel
                            active={ sort?.sortBy === "username"}
                            direction={sort?.sortBy === "username" ? sort?.sortDirection : 'asc'}
                            onClick={() => handleSort && handleSort({
                                sortBy: "username",
                                sortDirection: sort.sortBy === "username" && sort.sortDirection === 'asc' ? 'desc' : 'asc'
                            }) }>
                                Username
                                {sort?.sortBy === "username" ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {sort?.sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>) : null}
                        </TableSortLabel>
                        </TableCell>
                        <TableCell>
                        <TableSortLabel
                            active={ sort?.sortBy === "name"}
                            direction={sort?.sortBy === "name" ? sort?.sortDirection : 'asc'}
                            onClick={() => handleSort && handleSort({
                                sortBy: "name",
                                sortDirection: sort.sortBy === "name" && sort.sortDirection === 'asc' ? 'desc' : 'asc'
                            }) }>
                                Name
                                {sort?.sortBy === "name" ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {sort?.sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>) : null}
                        </TableSortLabel>
                        </TableCell>
                        <TableCell>
                        <TableSortLabel
                            active={ sort?.sortBy === "email"}
                            direction={sort?.sortBy === "email" ? sort?.sortDirection : 'asc'}
                            onClick={() => handleSort && handleSort({
                                sortBy: "email",
                                sortDirection: sort.sortBy === "email" && sort.sortDirection === 'asc' ? 'desc' : 'asc'
                            }) }>
                                Email
                                {sort?.sortBy === "email" ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {sort?.sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>) : null}
                        </TableSortLabel>
                        </TableCell>
                        <TableCell>
                        <TableSortLabel
                            active={ sort?.sortBy === "mobile"}
                            direction={sort?.sortBy === "mobile" ? sort?.sortDirection : 'asc'}
                            onClick={() => handleSort && handleSort({
                                sortBy: "mobile",
                                sortDirection: sort.sortBy === "mobile" && sort.sortDirection === 'asc' ? 'desc' : 'asc'
                            }) }>
                                Mobile
                                {sort?.sortBy === "mobile" ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {sort?.sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>) : null}
                        </TableSortLabel>
                        </TableCell>
                        <TableCell>Last Modified</TableCell>
                        <TableCell>Modified By</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {users ? ([...users].map((row) => (
                        <TableRow
                        key={row.id}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                        <TableCell component="th" scope="row">
                            <Link to={`/users/${row.id}`} state={{ searchTerm }}>{row.username}</Link>
                        </TableCell>
                        <TableCell>{row.relatedPerson?.givenName} {row.relatedPerson?.surname}</TableCell>
                        <TableCell>{row.email}</TableCell>
                        <TableCell>{row.mobile}</TableCell>
                        <TableCell>{new Date(row.lastModified).toLocaleString("en-US", defaultDateTimeOptions.dateTimeOptions)}</TableCell>
                        <TableCell>{row.modifiedBy}</TableCell>
                        </TableRow>
                    ))) : (
                        <TableRow
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                        <TableCell colSpan={6}>No results.</TableCell>
                        </TableRow>
                    )}
                    </TableBody>
                </Table>
                </TableContainer>
                    <FacilityFormDialog organizationId={organizationId} open={openDialog} onClose={closeFormDialog} fullWidth={true} maxWidth="md" />
                </>}                        
        </div>
    )
}

export default UserGrid