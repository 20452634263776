import { useState } from "react"
import Facility from "../../types/Facility"
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Typography, Alert, Button, Grid, Stack, TextField, Select, MenuItem, FormControl, FormHelperText, InputLabel, CircularProgress } from "@mui/material"
import { useCreateFacilityMutation, useUpdateFacilityMutation } from "../../services/facilityApi"
import { useGetAllOrganizationsQuery } from "../../services/organizationApi"

type FacilityFormProps = { facility:Facility, organizationId?:number, afterSave?:Function }
type FacilityFormValues = {
    id: number,
    name:string,
    websiteUrl?:string,
    phoneNumber?:string,
    organizationId:number,
    addressId: number,
    addressLine1: string,
    addressLine2: string,
    addressCity: string,
    addressState: string,
    addressPostalCode: string,
    addressCountry: string,
    addressLatitude: number,
    addressLongitude: number
}

const FacilityForm = ({facility, organizationId, afterSave}:FacilityFormProps) => {
    const [showAlert, setShowAlert] = useState(false)
    const [alertSeverity, setAlertSeverity] = useState<any>('success')
    const [alertContent, setAlertContent] = useState('')
    const [createFacility] = useCreateFacilityMutation()
    const [updateFacility] = useUpdateFacilityMutation()
    const {data:organizations, error, isLoading} = useGetAllOrganizationsQuery()
    
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        websiteUrl: Yup.string().optional().default(''),
        phoneNumber: Yup.string().optional().default(''),
        organizationId: Yup.number().required('Organization is required'),
        addressLine1: Yup.string().required('Line 1 is required'),
        addressLine2: Yup.string().optional().default(''),
        addressCity: Yup.string().required('City is required'),
        addressState: Yup.string().required('State/Province is required'),
        addressPostalCode: Yup.string().optional(),
        addressCountry: Yup.string().required('Country is required'),
        addressLatitude: Yup.number().required('Latitude is required'),
        addressLongitude: Yup.number().required('Longitude is required')
    })

    const mapFacilityToValues = (facility:Facility) : FacilityFormValues => {
        return facility && facility.id ? {
            id: facility.id,
            name: facility.name,
            websiteUrl: facility.websiteUrl,
            phoneNumber: facility.phoneNumber,
            organizationId: facility.organizationId,
            addressId: facility.address.id,
            addressLine1: facility.address.line1,
            addressLine2: facility.address.line2,
            addressCity: facility.address.city,
            addressState: facility.address.stateProvince,
            addressPostalCode: facility.address.postalCode,
            addressCountry: facility.address.country,
            addressLatitude: facility.address.latitude,
            addressLongitude: facility.address.longitude
        } satisfies FacilityFormValues : { organizationId: organizationId } as FacilityFormValues
    }

    const mapValuesToFacility = (values:FacilityFormValues) : Facility => {
        return {
            id: values.id,
            name: values.name,
            websiteUrl: values.websiteUrl,
            phoneNumber: values.phoneNumber,
            organizationId: values.organizationId || organizationId || 0,
            lastModified: new Date(),
            modifiedBy: 'system',
            status: 'ACTIVE',
            address: {
             id: values.addressId,
             line1: values.addressLine1,
             line2: values.addressLine2,
             city: values.addressCity,
             stateProvince: values.addressState,
             postalCode: values.addressPostalCode,
             country: values.addressCountry,
             latitude: values.addressLatitude,
             longitude: values.addressLongitude,
             lastModified: new Date(),
             modifiedBy: 'system',
             status: 'ACTIVE'   
            }
        } satisfies Facility
    }

    const formik = useFormik({
        initialValues: mapFacilityToValues(facility) as FacilityFormValues,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => handleSubmit(values)
    })

    const handleSubmit = async (values:FacilityFormValues) => {
        setShowAlert(false)
        setAlertContent('Saving...')
        setAlertSeverity('info')
        setShowAlert(true)

        const updateFn = (facility.id > 0 ? updateFacility : createFacility)
        
        try {
            let updatedFac = await updateFn(mapValuesToFacility(values)).unwrap()
            formik.setValues(mapFacilityToValues(updatedFac))
            setAlertContent(`Facility ${updatedFac.name} saved.`)
            setAlertSeverity('success')
            setShowAlert(true)
            afterSave && afterSave()            

        } catch (error:any) {
            formik.setErrors(error)
            setAlertContent('Error saving facility.')
            setAlertSeverity('error')
            setShowAlert(true)            
        }
    }
    
    return (
        isLoading ? (<CircularProgress/>) :
        (<form onSubmit={formik.handleSubmit}>
            <Grid container spacing={1}>
            {showAlert ? (
                <Grid item xs={12}>
                    <Alert severity={alertSeverity}>{alertContent}</Alert>    
                </Grid>
            ) : (<></>)}
                <Grid item xs={12}>
                    <FormControl sx={{m:1, minWidth:"200px"}}
                    error={formik.touched.organizationId && Boolean(formik.errors.organizationId)}
                    disabled={Boolean(organizationId) && !Boolean(formik.errors.organizationId)}>
                    <InputLabel id="facility-form-organization-id-label">Organization</InputLabel>
                    <Select
                        id="organizationId"
                        name="organizationId"
                        autoWidth
                        labelId="facility-form-organization-id-label"
                        label="Organization"
                        value={formik.values.organizationId}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}>
                        {organizations?.map((org) => (
                            <MenuItem value={org.id}>{org.name}</MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>{formik.touched.organizationId && formik.errors.organizationId}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        sx={{m: 1}}
                        id="name"
                        name="name"
                        label="Facility Name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}/>
                </Grid>
                <Grid item xs={12} md={6}>
                <TextField
                        sx={{m: 1}}
                        id="websiteUrl"
                        name="websiteUrl"
                        label="Website"
                        value={formik.values.websiteUrl}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.websiteUrl && Boolean(formik.errors.websiteUrl)}
                        helperText={formik.touched.websiteUrl && formik.errors.websiteUrl}/>
                </Grid>
                <Grid item xs={12} md={6}>
                <TextField
                        sx={{m: 1}}
                        id="phoneNumber"
                        name="phoneNumber"
                        label="Phone Number"
                        value={formik.values.phoneNumber}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                        helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}/>
                </Grid>                
                <Grid item xs={12}>
                    <Typography variant="h6">ADDRESS</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                <TextField
                        sx={{m: 1}}
                        fullWidth
                        id="addressLine1"
                        name="addressLine1"
                        label="Line 1"
                        value={formik.values.addressLine1}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.addressLine1 && Boolean(formik.errors.addressLine1)}
                        helperText={formik.touched.addressLine1 && formik.errors.addressLine1}/>
                
                </Grid>
                <Grid item xs={12} md={6}>
                <TextField
                        sx={{m: 1}}
                        fullWidth
                        id="addressLine2"
                        name="addressLine2"
                        label="Line 2"
                        value={formik.values.addressLine2}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.addressLine2 && Boolean(formik.errors.addressLine2)}
                        helperText={formik.touched.addressLine2 && formik.errors.addressLine2}/>
                
                </Grid>
                <Grid item xs={12} md={3}>
                <TextField
                        sx={{m: 1}}
                        id="addressCity"
                        name="addressCity"
                        label="City"
                        value={formik.values.addressCity}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.addressCity && Boolean(formik.errors.addressCity)}
                        helperText={formik.touched.addressCity && formik.errors.addressCity}/>                    
                </Grid>
                <Grid item xs={12} md={3}>
                <TextField
                        sx={{m: 1}}
                        id="addressState"
                        name="addressState"
                        label="State/Province Abbreviation"
                        value={formik.values.addressState}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.addressState && Boolean(formik.errors.addressState)}
                        helperText={formik.touched.addressState && formik.errors.addressState}/>                    
                </Grid>
                <Grid item xs={12} md={3}>
                <TextField
                        sx={{m: 1}}
                        id="addressCountry"
                        name="addressCountry"
                        label="Country Abbreviation"
                        value={formik.values.addressCountry}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.addressCountry && Boolean(formik.errors.addressCountry)}
                        helperText={formik.touched.addressCountry && formik.errors.addressCountry}/>                    
                </Grid>
                <Grid item xs={12} md={3}>
                <TextField
                        sx={{m: 1}}
                        id="addressPostalCode"
                        name="addressPostalCode"
                        label="Postal Code"
                        value={formik.values.addressPostalCode}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.addressPostalCode && Boolean(formik.errors.addressPostalCode)}
                        helperText={formik.touched.addressPostalCode && formik.errors.addressPostalCode}/>                    
                </Grid>
                <Grid item xs={12} md={4}>
                <TextField
                        sx={{m: 1}}
                        id="addressLatitude"
                        name="addressLatitude"
                        label="Latitude"
                        value={formik.values.addressLatitude}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.addressLatitude && Boolean(formik.errors.addressLatitude)}
                        helperText={formik.touched.addressLatitude && formik.errors.addressLatitude}/>                        
                </Grid>
                <Grid item xs={12} md={4}>
                <TextField
                        sx={{m: 1}}
                        id="addressLongitude"
                        name="addressLongitude"
                        label="Longitude"
                        value={formik.values.addressLongitude}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.addressLongitude && Boolean(formik.errors.addressLongitude)}
                        helperText={formik.touched.addressLongitude && formik.errors.addressLongitude}/>                        
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="end">
                        <Button color="primary" variant="contained" type="submit" sx={{m: 1, alignSelf: 'right'}}>
                        {formik.values.id ? ('Update') : ('Create')}
                        </Button>
                    </Stack>
                </Grid>                                                                             
            </Grid>
        </form>)
    )
}

export default FacilityForm
