import {Dialog, DialogTitle, Grid} from '@mui/material'
import FacilityForm from './FacilityForm'
import Facility from '../../types/Facility'

const FacilityFormDialog = (props:any) => {

    const {open, onClose, organizationId, afterSave, ...otherProps } = props

    return (
        <Dialog open={open} onClose={onClose} {...otherProps}>
            <DialogTitle>New Facility</DialogTitle>
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{m:2}}>
                    <FacilityForm facility={ {} as Facility } organizationId={organizationId} afterSave={afterSave}></FacilityForm>
                </Grid>
            </Grid>
        </Dialog>
    )
}

export default FacilityFormDialog