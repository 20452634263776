export const apiConstants = {
    BASE_URL: "https://api.icetimemobile.com/api/"
    //BASE_URL: "http://192.168.1.173:8080/api/",
    // BASE_URL: "http://172.20.10.5:8080/api/"
    // BASE_URL: "http://10.0.0.42:8080/api/",
    //BASE_URL: "http://172.168.7.19:8080/api/",
}

interface IOption {
    label: string,
    value: string|number|null|undefined
}

export const genderOptions : Array<IOption> = [
    { label: "-- Select --", value: null},
    { label: "Male", value: "MALE" },
    { label: "Female", value: "FEMALE" },
    { label: "Non-binary", value: "NONBINARY" },
    { label: "Prefer not to answer", value: "NOANSWER" },
]

export const contactInfoTypeOptions : Array<IOption> = [
    { label: "-- Select --", value: null},
    { label: "E-mail", value: "EMAIL" },
    { label: "Mobile", value: "MOBILE_PHONE" },
]

export const ageRangeOptions : Array<IOption> = [
    { label: "-- Select --", value: null},
    { label: "6 and under", value: "r6_UNDER" }, //learn to skate
    { label: "7-10", value: "r7_TO_10" }, //mite and squirt
    { label: "11-14", value: "r11_TO_14" }, //peewee and bantam
    { label: "15-18", value: "r15_TO_18" }, //midget    
    { label: "15-18", value: "r15_TO_18" },
    { label: "19-24", value: "r19_TO_24" },
    { label: "25-34", value: "r25_TO_34" },
    { label: "35-44", value: "r35_TO_44" },
    { label: "45-54", value: "r45_TO_54" },
    { label: "55+", value: "r55_PLUS" }
]

export const demographicRoleOptions : Array<IOption> = [
    { label: "-- Select --", value: null},
    { label: "Player/Skater", value: "PLAYER_SKATER" },
    { label: "Parent/Guardian", value: "PARENT" },
    { label: "Coach/Manager", value: "COACH" },
    { label: "Official", value: "OFFICIAL" },
    { label: "Judge", value: "JUDGE" },
    { label: "Other", value: "OTHER" }
]

export const demographicInterestOptions : Array<IOption> = [
    { label: "-- Select --", value: null},
    { label: "Hockey", value: "HOCKEY" },
    { label: "Sled Hockey", value: "SLED_HOCKEY" },
    { label: "Figure Skating", value: "FIGURE_SKATING" },
    { label: "Speed Skating", value: "SPEED_SKATING" },
    { label: "Curling", value: "CURLING" },
    { label: "Freestyle", value: "FREESTYLE" },
    { label: "Other", value: "OTHER" }
]

export const dayOfWeekValues = {
    Monday: "MONDAY",
    Tuesday: "TUESDAY",
    Wednesday: "WEDNESDAY",
    Thursday: "THURSDAY",
    Friday: "FRIDAY",
    Saturday: "SATURDAY",
    Sunday: "SUNDAY",
}

export const dayOfWeekOptions : Array<IOption> = [
    { label: "Monday", value: dayOfWeekValues.Monday },
    { label: "Tuesday", value: dayOfWeekValues.Tuesday },
    { label: "Wednesday", value: dayOfWeekValues.Wednesday },
    { label: "Thursday", value: dayOfWeekValues.Thursday },
    { label: "Friday", value: dayOfWeekValues.Friday },
    { label: "Saturday", value: dayOfWeekValues.Saturday },
    { label: "Sunday", value: dayOfWeekValues.Sunday }
]

export const pagination = {
    defaultItemsPerPage: 10,
    itemsPerPageOptions: [10, 25, 50, 100]
}

export const feedbackStatusValues = {
    Unread: "UNREAD",
    Read: "READ",
    Responded: "RESPONDED"
}

export const feedbackStatusOptions : Array<IOption> = [
    { label: "Read", value: feedbackStatusValues.Read },
    { label: "Unread", value: feedbackStatusValues.Unread },
    { label: "Responded", value: feedbackStatusValues.Responded }
]

export const feedbackSubjectValues = {
    NONE: "",
    BUG_REPORT: "BUG_REPORT",
    FACILITY_REQUEST: "FACILITY_REQUEST",
    SESSION_REQUEST: "SESSION_REQUEST",
    SERIES_REQUEST: "SERIES_REQUEST",
    QUESTION: "QUESTION",
    FEATURE_REQUEST: "FEATURE_REQUEST",
    GENERAL: "GENERAL"
}

export const feedbackSubjectOptions = [
    { label: "-- Select --", value: feedbackSubjectValues.NONE },
    { label: "Report a Bug", value: feedbackSubjectValues.BUG_REPORT },
    { label: "Request a New Facility", value: feedbackSubjectValues.FACILITY_REQUEST },
    { label: "Request a New Session(s)", value: feedbackSubjectValues.SESSION_REQUEST },
    { label: "Request a New Series", value: feedbackSubjectValues.SERIES_REQUEST },
    { label: "Ask a Question", value: feedbackSubjectValues.QUESTION },
    { label: "Request a New Feature", value: feedbackSubjectValues.FEATURE_REQUEST },
    { label: "General Feedback", value: feedbackSubjectValues.GENERAL },
]

export const defaultDateTimeOptions = {
    weekdayOptions: { weekday: "short" } as Intl.DateTimeFormatOptions,
    dateOptions: { dateStyle: "short" } as Intl.DateTimeFormatOptions,
    timeOptions: { timeStyle: "short", timeZone: "America/New_York" } as Intl.DateTimeFormatOptions,
    dateTimeOptions: { dateStyle: "short", timeStyle: "short", timeZone: "America/New_York" } as Intl.DateTimeFormatOptions
}