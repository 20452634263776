import Dashboard from "./Dashboard";
import ScheduleGrid from "../components/schedule/ScheduleGrid";
import { CircularProgress, Grid, Paper, TablePagination } from '@mui/material'
import { useGetAllSchedulesQuery, useLazyFindSchedulesBySearchTermPageQuery, useLazyGetSchedulePageQuery } from "../services/scheduleApi";
import { useEffect, useState } from "react";
import { pagination } from "../app/constants";
import Paged from "../types/Paged";
import Schedule from "../types/Schedule";
import { useDebounce } from "../app/hooks";
import ColumnSort from "../types/ColumnSort";
import { GetPagedArgs } from "../app/queries";

const FacilityPage = () => {
    //const {data:schedules, error, isLoading, refetch:refetchSchedules} = useGetAllSchedulesQuery()

    const [pageNumber, setPageNumber] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(pagination.defaultItemsPerPage)
    const [sort, setSort] = useState({ sortBy: "label", sortDirection: "asc"} as ColumnSort)
    const [isLoading, setIsLoading] = useState(false)
    const [schedulePage, setSchedulePage] = useState<Paged<Schedule>>(undefined)
    const [searchTerm, setSearchTerm]= useState<string|undefined>(undefined)
    const [getSchedulePage] = useLazyGetSchedulePageQuery()
    const [findSchedulesBySearchTermPage] = useLazyFindSchedulesBySearchTermPageQuery()
    const debouncedSearchTerm = useDebounce(searchTerm, 500)    

    useEffect(() => {
      setIsLoading(true)
      getSchedules()
      setIsLoading(false)
    },[pageNumber,itemsPerPage,sort,debouncedSearchTerm])    
    
    const getSchedules = () => {
      if (!debouncedSearchTerm || debouncedSearchTerm.length == 0) {
        fetchSchedulePage()
      } else {
        searchSchedulePage()
      }
    }

    const fetchSchedulePage = () => {
      const pageArgs : GetPagedArgs = {
        pageNumber,
        pageSize: itemsPerPage,
        sortBy: sort.sortBy,
        sortDirection: sort.sortDirection
      }

      getSchedulePage(pageArgs).then(sp => setSchedulePage(sp.data))
    }

    const searchSchedulePage = () => {
      const pageArgs : GetPagedArgs = {
        searchTerm: debouncedSearchTerm,
        pageNumber,
        pageSize: itemsPerPage,
        sortBy: sort.sortBy,
        sortDirection: sort.sortDirection
      }

      findSchedulesBySearchTermPage(pageArgs).then(sp => setSchedulePage(sp.data))
    }
    
    const handleSort = (updatedSort:ColumnSort) => {
      setSort(updatedSort)
      setPageNumber(1)
    }

    const handleSave = () => {
      getSchedules()
      setPageNumber(1)
      //setSort({ sortBy: "name", sortDirection: "asc" })
    }

    const handleChangePage = (event: unknown, newPage: number) => {
      setPageNumber(newPage+1);
    };
  
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setItemsPerPage(+event.target.value);
      setPageNumber(1);
    };
    
    const handleSearch = (searchTerm:string) => {
      setSearchTerm(searchTerm)
    }    
    return (
        <Dashboard>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                    { isLoading ? <CircularProgress/> : 
                    <>
                    <ScheduleGrid 
                          schedules={schedulePage ? schedulePage.items : []} 
                          afterSave={handleSave} 
                          handleSearch={handleSearch}
                          sort={sort}
                          handleSort={handleSort}/>
                    <TablePagination
                          rowsPerPageOptions={pagination.itemsPerPageOptions}
                          component="div"
                          count={schedulePage?.totalItems ?? 0}
                          rowsPerPage={itemsPerPage}
                          page={pageNumber-1}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage} />  
                    </>
                    }
                </Paper>
              </Grid>
            </Grid>
        </Dashboard>        
    )
}

export default FacilityPage