import { useParams } from "react-router-dom"
import { useGetOrganizationFacilitiesQuery } from "../../services/organizationApi"
import { CircularProgress, TablePagination } from "@mui/material"
import FacilityGrid from "./FacilityGrid"
import { useFindUpcomingSeriesByFacilityIdQuery } from "../../services/seriesApi"
import SeriesGrid from "../series/SeriesGrid"
import ColumnSort from "../../types/ColumnSort"
import { useEffect, useState } from "react"
import Series from "../../types/Series"
import { pagination } from "../../app/constants"

type FacSeriesGridProps = { organizationId?:number, facilityId?:number, showSeriesOnly?:boolean|false }
const FacilitySeriesGrid = ({organizationId, facilityId, showSeriesOnly}:FacSeriesGridProps) => {
    const {data:series, isLoading, error, refetch:refetchSeries} = useFindUpcomingSeriesByFacilityIdQuery({facilityId, showSeriesOnly})
    const [sort, setSort] = useState({ sortBy: "seriesname", sortDirection: "asc"} as ColumnSort)
    const [pageNumber, setPageNumber] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(pagination.defaultItemsPerPage)
    //const totalPages = series ? Math.ceil(series.length / itemsPerPage) : 1

    const getPagedSeries = (series:Series[]) => {
        let pagedSeries = [...series];

        if (series.length > itemsPerPage) {
            const sliceStart = (pageNumber - 1)*itemsPerPage
            const sliceLength = itemsPerPage
            const sliceEnd = Math.min(sliceStart+sliceLength, series.length)

            pagedSeries = [...series].slice(sliceStart, sliceEnd)
        }

        return pagedSeries;
    }

    const getSortedSeries = (series:Series[]) => {
        let sortedSeries = [...series];

        if (sort) {
            if (sort.sortBy) {
                let sortByProp = sort.sortBy ?? "seriesName";
                const sortDirection = sort.sortDirection ?? "asc";

                switch (sortByProp) {
                    case "date":
                        sortByProp = "startDate"
                        break
                    case "time":
                        sortByProp = "startTime"
                        break
                    case "usersavecount":
                        sortByProp = "userSaveCount"
                        break
                    case "seriesname":
                    default:
                        sortByProp = "seriesName"
                        break
                }

                if (series) {
                    sortedSeries = [...series].sort((a,b) => {
                        if (sortDirection === "desc") {
                            return a[sortByProp] > b[sortByProp] ? -1 : 1;
                        } else {
                            return a[sortByProp] <= b[sortByProp] ? -1 : 1;
                        }
                    })
                }
            }
        }
        
        return sortedSeries
    }

    const handleSort = (updatedSort:ColumnSort) => {
        setSort(updatedSort)
        setPageNumber(1)
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPageNumber(newPage+1);
    };
    
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setItemsPerPage(+event.target.value);
        setPageNumber(1);
    };    

    return (
        isLoading 
        ? (<CircularProgress/>)
        : (
        <>
        <SeriesGrid series={getPagedSeries(getSortedSeries(series ?? []))} allowNewSeries={true} afterSave={refetchSeries} sort={sort} handleSort={handleSort} organizationId={organizationId} facilityId={facilityId}/>
        <TablePagination
            rowsPerPageOptions={pagination.itemsPerPageOptions}
            component="div"
            count={series.length ?? 0}
            rowsPerPage={itemsPerPage}
            page={pageNumber-1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage} />        
        </>
        )
    )
}

export default FacilitySeriesGrid