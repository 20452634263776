import { APIProvider, Map, MapCameraChangedEvent, MapCameraProps, Marker } from "@vis.gl/react-google-maps";
import { Grid, Paper } from '@mui/material'
import Facility from "../../types/Facility"
import { useCallback, useState } from "react";

type FacilitiesMapProps = { facilities:Facility[] }
const FacilitiesMap = ({facilities}:FacilitiesMapProps) => {

    const mapCenter = { lat: 39.8, lng: -98.5 }
    const mapZoom = 4

    const [cameraProps, setCameraProps] = useState<MapCameraProps>({center: mapCenter, zoom: mapZoom});
    const handleCameraChange = (ev: MapCameraChangedEvent) => setCameraProps(ev.detail);    

    return (
        <APIProvider apiKey="AIzaSyAVRJYOW8mg7wJEIEAJaGJwZlH0KXt1UR4">
            <Map {...cameraProps} onCameraChanged={handleCameraChange} style={{height:500}}>
                {
                    [...facilities].map((facility) =>
                        (<Marker
                            key={facility.id}
                            position={{lat: facility.address.latitude, lng: facility.address.longitude}}
                            title={facility.name}></Marker>))
                }
            </Map>
        </APIProvider>
    )
}

export default FacilitiesMap

